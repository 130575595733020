import { forwardRef, HTMLTag } from '@melio/platform-utils';
import { ComponentType, useContext, useMemo, useRef } from 'react';

import { AnalyticsContext } from '../AnalyticsProvider';
import { EventProperties } from '../types';

export type WithAnalyticsContextProps = {
  setAnalyticsProperties: (properties?: EventProperties | undefined) => void;
};

export function withAnalyticsContext<T, S extends HTMLTag = 'div', TChildren extends boolean = false>(
  UI: ComponentType<T & WithAnalyticsContextProps>
) {
  const AnalyticsWrapper = forwardRef<Omit<T, 'setAnalyticsProperties'>, S, TChildren>((props, ref) => {
    const { getGlobalProperties } = useContext(AnalyticsContext);
    const propsRef = useRef({});

    const value = useMemo(
      () => ({
        setGlobalProperties: (properties?: EventProperties) => {
          propsRef.current = { ...propsRef.current, ...properties };
        },
        getGlobalProperties: () => ({
          ...getGlobalProperties(),
          ...propsRef.current,
        }),
      }),
      [getGlobalProperties]
    );

    return (
      <AnalyticsContext.Provider value={value}>
        <UI {...(props as T)} setAnalyticsProperties={value.setGlobalProperties} ref={ref} />
      </AnalyticsContext.Provider>
    );
  });
  AnalyticsWrapper.displayName = 'AnalyticsWrapper';
  return AnalyticsWrapper;
}

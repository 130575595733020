import { PaginationResponse } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQueryClient } from '@tanstack/react-query';
import { isString } from 'lodash';

import {
  ApiError,
  ApiQueryKeyV2,
  EntityQueryKeyV2,
  EntityWithId,
  ModelId,
  OnMutationError,
  OnMutationMutate,
  OnMutationSuccess,
  QueryKeyEnumV2,
  Role,
  Scope,
} from './types';
import { useMelioQueryClientV2 } from './useMelioQueryClient';

export const defaultScope: Scope = 'default';

type QueryKeyParamsV2 = {
  queryKey: EntityQueryKeyV2;
  role: Role;
  scope: Scope;
  id?: ModelId;
  params?: unknown[];
};

const cleanQueryKeyV2 = (queryKey: EntityQueryKeyV2): [QueryKeyEnumV2, unknown[]] => {
  if (isString(queryKey)) {
    return [queryKey, []];
  }
  if (Array.isArray(queryKey)) {
    const [key, ...rest] = queryKey;
    if (!isString(key)) {
      throw new Error(`expected the first element of queryKey to be a string, found ${typeof key}`);
    }
    return [key, rest.filter((value) => !!value)];
  }
  throw new Error('expected queryKey to be a string or an array');
};

export function createQueryKeyV2({ queryKey: key, role, scope, id, params = [] }: QueryKeyParamsV2): ApiQueryKeyV2 {
  const [queryKey, extraParams] = cleanQueryKeyV2(key);
  if (role === 'collection' || role === 'paginated-collection') {
    return [queryKey, role, scope, ...extraParams, ...params];
  } else {
    return [queryKey, role, id, scope, ...extraParams, ...params];
  }
}

export function useOnUpdateMutateV2<TData, TVariables, TError = ApiError>(
  queryKey: ApiQueryKeyV2
): OnMutationMutate<TData, TVariables, TError> {
  const queryClient = useQueryClient();
  return async (data: TVariables) => {
    await queryClient.cancelQueries(queryKey);
    const previousData = queryClient.getQueryData<TData>(queryKey);
    queryClient.setQueriesData(queryKey, data);
    return previousData ? { previousData } : {};
  };
}

export function useOnUpdateErrorV2<TData, TVariables, TError = ApiError>(queryKey: ApiQueryKeyV2) {
  const queryClient = useQueryClient();
  const onError: OnMutationError<TData, TVariables, TError> = (_error, _dataToUpdate, context) => {
    if (context?.previousData) {
      queryClient.setQueriesData(queryKey, context.previousData);
    }
    return Promise.reject(_error);
  };
  return onError;
}

export const useOnUpdateSuccessV2 = <TData, TVariables = Partial<TData>, TError = ApiError>(
  queryKey: ApiQueryKeyV2
): OnMutationSuccess<TData, TVariables, TError> => {
  const queryClient = useMelioQueryClientV2();
  const [entity, , modelId] = queryKey;

  const getUpdatedCollectionData = (data: TData & EntityWithId) => (collectionData?: (TData & EntityWithId)[]) =>
    collectionData?.map((current) => (current.id === data.id ? data : current)) || [];

  type PaginationData = { data: (TData & EntityWithId)[] } & PaginationResponse;
  const getUpdatedPaginationData = (data: TData & EntityWithId) => (collectionData?: PaginationData) => ({
    data: collectionData?.data?.map((current) => (current.id === data.id ? data : current)) || [],
    pagination: collectionData?.pagination ?? { totalCount: 0, cursors: { prev: null, next: null } },
  });

  return (modelData) => {
    queryClient.setQueriesData([entity, 'model', modelId], modelData);
    queryClient.setQueriesData([entity, 'collection'], getUpdatedCollectionData(modelData as never));
    queryClient.setQueriesData([entity, 'paginated-collection'], getUpdatedPaginationData(modelData as never));
  };
};

import { IconProps } from '@melio/penny';
import {
  BankAccount,
  Card,
  DeliveryMethodType,
  DeliveryPreferenceType,
  formatAddress,
  PaperCheck,
  PaymentStatusEnum,
  PaymentUpgradeOfferOption,
  PaymentUpgradeOfferOptionEnum,
  VexGuestPaymentResponse,
} from '@melio/platform-api';
import { extractBankAccountNumberLastFourDigits } from '@melio/vex-domain';

export const getUpgradeOfferByType = (
  upgradeOffers: Array<PaymentUpgradeOfferOption>,
  offerType: PaymentUpgradeOfferOptionEnum
) => upgradeOffers.find((upgradeOffer) => upgradeOffer.offerType === offerType);

export const getUpgradeTypeOfPayment = (payment?: VexGuestPaymentResponse) => {
  if (payment?.deliveryPreference === DeliveryPreferenceType.Rtp) {
    return PaymentUpgradeOfferOptionEnum.Rtp;
  }
  if (payment?.deliveryPreference === DeliveryPreferenceType.ExpeditedAch) {
    return PaymentUpgradeOfferOptionEnum.Fach;
  }
  if (payment?.deliveryMethod?.type === DeliveryMethodType.Card) {
    return PaymentUpgradeOfferOptionEnum.Debit;
  }
  return null;
};

export const getIsPaymentStateFinal = (payment?: VexGuestPaymentResponse) =>
  payment?.deliveryMethod?.type === DeliveryMethodType.PaperCheck
    ? false
    : payment?.status === PaymentStatusEnum.Completed;

export const getIsPaymentFailed = (payment?: VexGuestPaymentResponse) => payment?.status === PaymentStatusEnum.Failed;

export const getFormattedDeliveryMethodDetails = (payment?: VexGuestPaymentResponse): string => {
  if (payment?.deliveryMethod?.type === DeliveryMethodType.BankAccount) {
    return extractBankAccountNumberLastFourDigits(payment.deliveryMethod.details as BankAccount) || '';
  }
  if (payment?.deliveryMethod?.type === DeliveryMethodType.Card) {
    return (payment.deliveryMethod.details as Card).lastFourDigits;
  }
  if (payment?.deliveryMethod?.type === DeliveryMethodType.PaperCheck) {
    const { printName, address } = payment.deliveryMethod.details as PaperCheck;
    return `${printName} | ${formatAddress(address)}`;
  }
  return '';
};

export const getIsACHUpgradeSupported = (paymentUpgradeOffers: Array<PaymentUpgradeOfferOption>) =>
  Boolean(
    getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Rtp) ||
      getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Fach)
  );

export const getDeliveryMethodIconType = (deliveryMethodType?: DeliveryMethodType): IconProps['type'] => {
  switch (deliveryMethodType) {
    case DeliveryMethodType.PaperCheck:
      return 'paper-check';
    case DeliveryMethodType.BankAccount:
      return 'bank-in';
    case DeliveryMethodType.Card:
      return 'debit-card';
    default:
      return 'bank-in';
  }
};

export const getUpsellOfferDataByPriority = (paymentUpgradeOffers: Array<PaymentUpgradeOfferOption>) => {
  const rtpUpsellOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Rtp);
  if (rtpUpsellOffer) {
    return {
      fee: rtpUpsellOffer.feeCatalog.value,
      isInstantlyUpsellOffer: true,
    };
  }

  const fastAchUpsellOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Fach);

  if (fastAchUpsellOffer) {
    return {
      fee: fastAchUpsellOffer.feeCatalog.value,
      isInstantlyUpsellOffer: false,
    };
  }

  const p2dUpsellOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Debit);

  if (p2dUpsellOffer) {
    return {
      fee: p2dUpsellOffer.feeCatalog.value,
      isInstantlyUpsellOffer: true,
    };
  }

  return null;
};

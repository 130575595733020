import { ExternalLayout } from '@melio/penny';
import { useIsMobile } from '@melio/vex-widgets';
import { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';

import { ExternalLayoutContentWidgetCTASectionProps, ExternalLayoutContentWidgetFormProps } from './components';
import { ExternalLayoutContentWidget } from './ExternalLayoutContent.widget';

type ExternalLayoutWithCTAProps = HTMLAttributes<HTMLDivElement> & {
  header?: { backButtonProps?: { isDisabled?: boolean; onClick: VoidFunction }; title: ReactNode };
  form?: ExternalLayoutContentWidgetFormProps;
  ctaSection?: ExternalLayoutContentWidgetCTASectionProps;
  footer?: React.ReactElement;
  ref: ForwardedRef<HTMLDivElement>;
  children?: never;
};

export const ExternalLayoutWithCTA = forwardRef<HTMLDivElement, ExternalLayoutWithCTAProps>(
  ({ header, form, ctaSection, footer, ...props }, ref) => {
    const isMobile = useIsMobile();

    const content = (
      <ExternalLayoutContentWidget header={header} form={form} ctaSection={ctaSection} {...(isMobile && { footer })} />
    );

    return <ExternalLayout {...props} content={content} {...(!isMobile && { footer })} ref={ref} />;
  }
);

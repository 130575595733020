import { InternationalAddress } from '@melio/platform-api-axios-client';
import { identity } from 'lodash';

import { AccountAddress, Address } from './types';

export const formatAddress = (address: Address | AccountAddress) => {
  const { city, line1, postalCode, state, line2 } = address;
  return [[line1, line2].filter(identity).join(' '), city, [state, postalCode].filter(identity).join(' ')]
    .filter(identity)
    .join(', ');
};

export const formatInternationalAddress = (address: InternationalAddress) => {
  const { city, line1, postalCode, state, line2, countryCode } = address;
  return [[line1, line2].filter(identity).join(' '), city, [state, postalCode].filter(identity).join(' '), countryCode]
    .filter(identity)
    .join(', ');
};

import { SettingsCardIdEnum } from '../cl/components/SettingsCard/SettingsCard.component';
import { SettingsGroupEnum } from '../partnersConfig.types';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const paypalEnvConfig: PartnerConfigByEnv = {
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/paypal/messages.json',
    settings: {
      showRetryButtonOnFieldValidation: false,
      supportEmail: 'accounts-payable@paypal.com',
      externalIdsPassedForNavigation: true,
      collectMissingCompanyLegalInfoFields: false,
      ShowVendorDetailsModalForVisa: {
        enabled: false,
      },
      ShowVendorDetailsModalForAmex: {
        enabled: false,
      },
      OnboardingModalEnabled: {
        flagName: 'platform-feature-onboarding-modal-enabled',
        enabled: false,
      },
      payment: {
        scheduling: {
          showFuturePaymentBanner: false,
          goodsReceivedModal: {
            enabled: false,
          },
        },
        memoToVendor: {
          deliveryMethodBlackList: [
            'biller-account',
            'managed-account',
            'paper-check',
            'card',
            'bank-account',
            'virtual-card',
            'paypal-balance',
            'international-account',
            'virtual-account',
          ],
        },
        newPaymentFlow: {
          enabled: true,
        },
        supportedFundingSourceTypes: {
          'paypal-balance': true,
        },
        flowOfFundsIndicator: {
          enabled: true,
          showDateRange: true,
        },
      },
      MarkAsPaid: {
        enabled: false,
      },
      embeddedExperience: {
        enabled: true,
      },
      hideHeaderNavigation: true,
      hideSettingsNavigation: true,
      fundingSourcePolicy: {
        'paypal-balance': {
          write: false,
          read: true,
          order: 0,
        },
        'bank-account': {
          write: true,
          read: true,
          creationHosting: 'iframe',
          order: 1,
        },
        card: {
          write: true,
          read: true,
          creationHosting: 'iframe',
          order: 2,
        },
      },
      deliveryMethodTypeOrder: {
        'paypal-balance': 1,
        'bank-account': 2,
        'paper-check': 3,
        'domestic-wire-account': 4,
        'virtual-card': 5,
        'virtual-account': 6,
        'international-account': 7,
      },
      settings: {
        options: [
          {
            type: SettingsGroupEnum.COMPANY,
            items: [
              SettingsCardIdEnum.ProfileSettings,
              SettingsCardIdEnum.CompanySettings,
              SettingsCardIdEnum.CollaboratorsSetting,
              SettingsCardIdEnum.NotificationPreferences,
              SettingsCardIdEnum.AccountingSoftwareSync,
            ],
          },
          {
            type: SettingsGroupEnum.PAYMENTS,
            items: [
              SettingsCardIdEnum.PaymentMethodsSetting,
              SettingsCardIdEnum.SubscriptionPlansSettings,
              SettingsCardIdEnum.BillingSetting,
            ],
          },
          {
            type: SettingsGroupEnum.SUPPORT,
            items: [SettingsCardIdEnum.SupportSetting],
          },
        ],
      },
      PaypalBalanceDeliveryMethodEnabled: {
        enabled: true,
      },
      ShowTotalPaymentIncludingFees: {
        enabled: true,
      },
      VendorDeliveryMethodCombinedExperience: {
        enabled: true,
      },
      vendor: {
        forms: {
          shouldRequireEmail: true,
          shouldDisableEmailEdit: true,
          shouldCollectCountry: true,
        },
        vendorSelect: {
          createVendor: {
            showVendorDetailsForm: { enabled: true },
            vendorDetailsFormType: 'Drawer',
          },
        },
        createVendor: {
          showCompanyNameTooltip: true,
          shouldShowDeliveryMethodsSection: true,
          shouldEnforceAddingDeliveryMethod: true,
        },
      },
      isBillsInboxEnabled: false,
      isUploadBillsEnabled: false,
      batchPayments: {
        enabled: false,
      },
      InternationalWire: {
        flagName: 'international-wire',
        enabled: true,
      },
    },
    security: {
      hosts: ['*'],
    },
  },
  prod: {
    redirectWhenMissingToken: false,
    security: {
      hosts: ['https://www.paypal.com'],
    },
  },
  dev: {
    redirectWhenMissingToken: true,
    security: {
      hosts: ['*'],
    },
  },
};

export const paypalConfig = extendDefaultConfig(paypalEnvConfig);

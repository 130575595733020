import { withAnalyticsContext } from '@melio/platform-analytics';
import { useBusinessDetailsUpdate } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { CollectBusinessDetailsFormModel } from './components';
import { CollectBusinessDetailsScreen } from './screens';

type CollectBusinessDetailsActivityProps = {
  businessId: string;
  businessLegalName: string;
  businessEmail: string;
  onSuccess: VoidFunction;
  onError?: VoidFunction;
};

export const CollectBusinessDetailsActivity = withAnalyticsContext(
  ({ businessId, businessLegalName, businessEmail, onSuccess, onError }: CollectBusinessDetailsActivityProps) => {
    const { mutateAsync: updateBusinessDetails, isLoading: isBusienssDetailsUpdateLoading } =
      useBusinessDetailsUpdate();
    const { showMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();

    const onSubmit = async (formData: CollectBusinessDetailsFormModel) => {
      try {
        await updateBusinessDetails({
          accountNumber: formData.accountNumber,
          routingNumber: formData.routingNumber,
          email: formData.email,
          legalName: formData.businessName,
        });
        onSuccess();
      } catch (error) {
        showMessage({
          title: formatMessage('vex.activities.collectBusinessDetails.error.title'),
          type: 'error',
        });
        onError?.();
      }
    };

    return (
      <CollectBusinessDetailsScreen
        onSubmit={onSubmit}
        businessId={businessId}
        businessLegalName={businessLegalName}
        businessEmail={businessEmail}
        isLoading={isBusienssDetailsUpdateLoading}
      />
    );
  }
);

CollectBusinessDetailsActivity.displayName = 'CollectBusinessDetailsActivity';

import { IconKey, Image, PennyConfig, PennyProvider, ThemeIllustrationType, ThemeOptions } from '@melio/penny';
import { LazyAnimation } from '@melio/platform-utils';
import { useMemo } from 'react';

import { DSProps, Illustration } from './types';

export type DesignSystemProviderProps = Omit<DSProps, 'config'> & { pennyConfig?: PennyConfig };

export function toDSTheme(theme: DSProps['theme']): ThemeOptions {
  const illustrations = Object.fromEntries(
    (Object.entries(theme.illustrations ?? {}) as [ThemeIllustrationType, Illustration][]).map<
      [ThemeIllustrationType, React.ComponentType]
    >(([key, value]) => [
      key,
      () =>
        value.type === 'animation' ? (
          <LazyAnimation id="expired" src={value.src} width="100%" height="100%" />
        ) : (
          <Image src={value.src} alt={key} height="auto" width="auto" />
        ),
    ])
  );

  const icons = Object.fromEntries(
    (Object.entries(theme.icons ?? {}) as [IconKey, string][]).map<[IconKey, React.ComponentType]>(
      ([key, value]: [IconKey, string]) => [key, () => <Image src={value} alt={key} height="auto" width="auto" />]
    )
  );

  return {
    ...theme,
    illustrations,
    icons,
    logos: {
      light: () => (theme.logos?.light ? <Image key={theme.logos?.light} src={theme.logos?.light} alt="logo" /> : null),
      dark: () => (theme.logos?.dark ? <Image key={theme.logos?.dark} src={theme.logos?.dark} alt="logo" /> : null),
    },
  };
}

export const DesignSystemProvider = ({ children, theme, pennyConfig, ...rest }: DesignSystemProviderProps) => {
  const dsTheme = useMemo(() => toDSTheme(theme), [theme]);
  return (
    <PennyProvider {...rest} config={pennyConfig} theme={dsTheme}>
      {children}
    </PennyProvider>
  );
};

import { createContext, useContext } from 'react';

import { CreateConfig } from '../../hooks/useActivityConfigData';

export type ActivityConfig = {
  shouldSuggestJoinMelio: boolean;
  persistentFastOptOutArticleUrl: string;
};

export const createActivityConfig: CreateConfig<ActivityConfig> = (appConfig) => ({
  shouldSuggestJoinMelio: appConfig.vex.unilateral.shouldSuggestJoinMelio ?? false,
  persistentFastOptOutArticleUrl: appConfig.vex.persistentFastOptOutArticleUrl,
});

export const UpgradePaymentFromCheckConfigContext = createContext<ActivityConfig>({} as ActivityConfig);
export const useActivityContext = () => useContext<ActivityConfig>(UpgradePaymentFromCheckConfigContext);

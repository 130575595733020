import { datadogRum } from '@datadog/browser-rum';
import React, { ComponentProps } from 'react';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';

export function ErrorBoundary({ onError: _onError, ...props }: ComponentProps<typeof _ErrorBoundary>) {
  const onError: ComponentProps<typeof _ErrorBoundary>['onError'] = (error: Error | string, info) => {
    const errorObj = error instanceof Error ? error : new Error(error);
    const componentError = new Error(errorObj.message);
    componentError.name = `React ErrorBoundary ${componentError.name}`;
    componentError.stack = info.componentStack || undefined;
    componentError.cause = error;

    datadogRum.addError(componentError, {
      is_crash: true,
    });

    _onError?.(componentError, info);
  };

  return <_ErrorBoundary {...props} onError={onError} />;
}

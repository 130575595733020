import { merge } from 'lodash';

import { fiservConfigBase } from './fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const fiservFirstFinancialConfigBase: PartnerConfigByEnv = merge<PartnerConfigByEnv>({}, fiservConfigBase, {
  default: {
    settings: {
      TermsAndConditions: {
        flagName: 'sign-terms-and-conditions',
        enabled: true,
      },
    },
  },
  dev: {},
  prod: {},
});

export const fiservFirstFinancialConfig = extendDefaultConfig(fiservFirstFinancialConfigBase);

import { merge } from 'lodash';
import { Tier } from '@melio/subscriptions';

import { fiservConfigBase } from './fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const fiservUsBankConfigBase: PartnerConfigByEnv = merge<PartnerConfigByEnv>({}, fiservConfigBase, {
  default: {
    settings: {
      payment: {
        scheduling: {
          fundingSourceTypeSelectionEnabled: false,
        },
      },
      embeddedExperience: {
        maxWidth: '1200px',
        singleScrollOwnedByHost: false,
      },
      AllowCaratIntegration: {
        enabled: false,
      },
      subscriptions: {
        hideFreePlanIndication: true,
        plansMessagesOverride: {
          [Tier.FiservApBasic]: {
            planName: 'Bill pay Standard plan',
          },
          [Tier.FiservApPro]: {
            planName: 'Bill pay Pro plan',
          },
          [Tier.FiservApArBasic]: {
            planName: 'Bill pay & Invoices Standard plan',
          },
          [Tier.FiservApArPro]: {
            planName: 'Bill pay & Invoices Pro plan',
          },
        },
      },
    },
  },
  dev: {},
  prod: {},
});

export const fiservUsBankConfig = extendDefaultConfig(fiservUsBankConfigBase);

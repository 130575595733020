import { Big } from 'big.js';
import { useCallback, useContext } from 'react';
import { FormatNumberOptions, IntlFormatters, useIntl } from 'react-intl';

import { LocalizatioMetaContext } from './AsyncLocalizationProvider';
import { BaseTranslations, Renderers, ValidatedFormatNumberOptions } from './types';

export type FormatMessage<T extends BaseTranslations> = (id: keyof T, values?: Record<string, unknown>) => string;
type FormatNumberToParts = IntlFormatters['formatNumberToParts'];
export type FormatCurrency = (amount: number, currency?: string, options?: ValidatedFormatNumberOptions) => string;
export type FormatPercents = (amount: number, options?: FormatNumberOptions & { divide?: boolean }) => string;
export type FormatDate = IntlFormatters['formatDate'];
export type FormatDateTimeRange = IntlFormatters['formatDateTimeRange'];
export type FormatDisplayName = (currency: string) => string;

export type MelioIntl<T extends BaseTranslations> = {
  isLoading: boolean;
  locale: string;
  timeZone: string;
  formatMessage: FormatMessage<T>;
  formatCurrency: FormatCurrency;
  formatPercents: FormatPercents;
  formatDate: FormatDate;
  formatDateTimeRange: FormatDateTimeRange;
  formatNumberToParts: FormatNumberToParts;
  getCurrencyName: FormatDisplayName;
};

export function useMelioIntl<T extends BaseTranslations>(renderers: Renderers = {}): MelioIntl<T> {
  const intl = useIntl();
  const { isLoading } = useContext(LocalizatioMetaContext);
  const { timeZone, locale } = intl;

  const formatCurrency = useCallback<FormatCurrency>(
    (amount, currency = 'USD', options) =>
      intl.formatNumber(amount, {
        currencyDisplay: 'narrowSymbol',
        ...options,
        style: 'currency',
        currency,
      }),
    [intl]
  );

  const getCurrencyName = useCallback<FormatDisplayName>(
    (currency) => intl.formatDisplayName(currency, { type: 'currency' }) ?? '',
    [intl]
  );

  const formatPercents = useCallback<FormatPercents>(
    (amount, { divide, ...options } = {}) => {
      const target = divide && amount ? new Big(amount).div(100).toNumber() : amount;
      return intl.formatNumber(target, { style: 'percent', maximumFractionDigits: 2, ...options });
    },
    [intl]
  );

  const formatMessage = useCallback<FormatMessage<T>>(
    (id, values) => {
      const refRenderer = (...chunks: unknown[]) =>
        intl.formatMessage({ id: chunks.join('') }, { ...values, ...renderers });
      return intl.formatMessage({ id: id as string }, { ...values, ...renderers, ref: refRenderer });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  const formatDate = useCallback((...params: Parameters<typeof intl.formatDate>) => intl.formatDate(...params), [intl]);
  const formatDateTimeRange = intl.formatDateTimeRange;
  const formatNumberToParts = intl.formatNumberToParts;

  return {
    isLoading,
    locale,
    timeZone: timeZone as string,
    formatMessage,
    formatCurrency,
    formatPercents,
    formatDate,
    formatDateTimeRange,
    formatNumberToParts,
    getCurrencyName,
  };
}

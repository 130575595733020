import { NakedButton, SectionBanner, useBreakpoint, useToast, UseToastOptions } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { debounce } from 'lodash';
import { ComponentProps, createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

export type SystemMessage = {
  action?: UseToastOptions['action'] & { role?: string };
  type: UseToastOptions['type'];
  title: UseToastOptions['title'];
  /**
   * @deprecated
   * Deprecated!!
   * Used for migration purposes only.
   * This prop will be removed after the migration is done.
   */
  id?: UseToastOptions['id'];
  /**
   * Always prefer testing message by testing the content of the message.
   */
  dataTestId?: string;
};
type SystemMessageContextType = {
  message: SystemMessage | null;
  showMessage: (message: SystemMessage) => void;
  hideMessage: () => void;
};

type Props = {
  children: ReactNode;
};

const SystemMessageContext = createContext<SystemMessageContextType | undefined>(undefined);
export const SystemMessageProvider = ({ children }: Props) => {
  const [message, setMessageState] = useState<SystemMessage | null>(null);
  const [isApToastToBannerMigration] = useDevFeature(FeatureFlags.ApToastToBannerMigration, false);
  const { toast, closeToast } = useToast();

  const showMessage = useCallback(
    (newMessage: SystemMessage) => {
      if (isApToastToBannerMigration) {
        setMessageState(newMessage);
      } else {
        toast(newMessage);
      }
    },
    [isApToastToBannerMigration, toast]
  );

  const hideMessage = useCallback(() => {
    if (isApToastToBannerMigration) {
      setMessageState(null);
    } else {
      closeToast();
    }
  }, [closeToast, isApToastToBannerMigration]);

  return (
    <SystemMessageContext.Provider value={{ message, showMessage, hideMessage }}>
      {children}
    </SystemMessageContext.Provider>
  );
};

export const useSystemMessage = () => {
  const context = useContext(SystemMessageContext);
  if (context === undefined) {
    throw new Error('useSystemMessage must be used within a SystemMessageProvider');
  }
  return context;
};

type SectionBannerProps = ComponentProps<typeof SectionBanner>;

function toSectionBannerVariant(type?: SystemMessage['type']): SectionBannerProps['variant'] {
  switch (type) {
    case 'success':
      return 'success';
    case 'error':
      return 'critical';
    case 'informative':
      return 'informative';
    default:
      return 'informative';
  }
}

function getPreviouslyFocusedElement(banner: HTMLDivElement, event?: FocusEvent): Element | null {
  if (
    event instanceof FocusEvent &&
    event.relatedTarget instanceof HTMLElement &&
    !banner.contains(event.relatedTarget)
  ) {
    return event.relatedTarget;
  } else if (!banner.contains(document.activeElement)) {
    return document.activeElement;
  }

  return null;
}

export const SystemMessageDisplay = (props: { 'data-testid'?: string }) => {
  const { formatMessage } = useMelioIntl();
  const { message, hideMessage } = useSystemMessage();
  const { isExtraSmallScreen } = useBreakpoint();
  const [displayedMessage, setDisplayedMessage] = useState<SystemMessage | null>(null);
  const previousActiveElement = useRef<Element | null>();
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const rafId = useRef<number>(0);

  // not a hack, but rather a bypass to a bug in eslint-plugin-react-hooks that doesnt recognize dependencies within debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const focusOnBanner = useCallback(
    debounce(() => {
      //This is required to allow the UI to stabalize, otherwise the focus can move, but the screen reader
      //will not read it out
      requestAnimationFrame(() => {
        bannerRef.current?.scrollIntoView({ behavior: 'smooth' });
        bannerRef.current?.focus();
      });
    }, 1000),
    []
  );

  const attemptFocusOnBanner = useCallback(
    (event?: FocusEvent) => {
      if (bannerRef.current) {
        previousActiveElement.current = getPreviouslyFocusedElement(bannerRef.current, event);

        focusOnBanner();
      }
    },
    [focusOnBanner]
  );

  const cleanup = useCallback(() => {
    document.removeEventListener('focusin', attemptFocusOnBanner);
    focusOnBanner.cancel();
    cancelAnimationFrame(rafId.current);
  }, [attemptFocusOnBanner, focusOnBanner]);

  useEffect(() => {
    if (message) {
      setDisplayedMessage(message);
      hideMessage();
      rafId.current = requestAnimationFrame(() => attemptFocusOnBanner());
      document.addEventListener('focusin', attemptFocusOnBanner);
    }
  }, [message, hideMessage, attemptFocusOnBanner]);

  useEffect(
    () => () => {
      timeoutId.current && clearTimeout(timeoutId.current);
      cleanup();
    },
    [cleanup]
  );

  const onMessageShown = useCallback(
    (ref: HTMLDivElement | null): void => {
      if (ref) {
        const handleFocusOut = () => {
          // Delay to allow focus to move to another element
          timeoutId.current = setTimeout(() => {
            if (!ref.contains(document.activeElement)) {
              previousActiveElement.current = null;
            }
          });
        };
        ref.addEventListener('focusin', () => {
          cleanup();
        });
        ref.addEventListener('focusout', handleFocusOut);
        bannerRef.current = ref;
      } else {
        previousActiveElement.current = null;
        bannerRef.current = null;
        cleanup();
      }
    },
    [cleanup]
  );

  const onClose = useCallback(() => {
    if (previousActiveElement.current instanceof HTMLElement) {
      previousActiveElement.current?.focus();
    }
    setDisplayedMessage(null);
  }, []);

  if (!displayedMessage && !message) {
    return null;
  }

  const onAction = displayedMessage?.action?.onAction;
  const actionText = displayedMessage?.action?.text;
  const actionRole = displayedMessage?.action?.role;

  const action = actionText && onAction && (
    <NakedButton
      onClick={() => onAction?.(() => setDisplayedMessage(null))}
      size="medium"
      variant="primary"
      data-testid={`${props['data-testid'] ?? ''}-action`}
      label={actionText}
      role={actionRole}
    />
  );

  return (
    <section aria-label={formatMessage('app.notifications.ariaLabel')}>
      {displayedMessage && (
        <SectionBanner
          ref={onMessageShown}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tabIndex="-1"
          data-testid={props['data-testid']}
          data-message-test-id={displayedMessage?.dataTestId}
          description={displayedMessage?.title}
          variant={toSectionBannerVariant(displayedMessage?.type)}
          showCloseIcon
          size={isExtraSmallScreen ? 'small' : 'large'}
          isCompact={!isExtraSmallScreen}
          action={action}
          closeButtonAriaLabel={formatMessage('app.notifications.close')}
          onClose={onClose}
        />
      )}
    </section>
  );
};

import { Theme } from '@melio/platform-provider';

export const fiservTheme: Theme = {
  logos: {
    light: 'https://platform-static.meliopayments.com/assets/fiserv/FiservLogo.svg',
    dark: 'https://platform-static.meliopayments.com/assets/fiserv/FiservLogoDark.svg',
  },
  fonts: {
    primary: '"Univers For Fiserv", Roboto,Arial,sans-serif',
  },
  colors: {
    global: {
      brand: {
        1000: '#002038',
        900: '#003055',
        800: '#004071',
        700: '#00508D',
        600: '#4989BB',
        500: '#639AC5',
        400: '#7FB4DD',
        300: '#92C2E8',
        200: '#A4D1F4',
        100: '#D3ECFF',
      },
    },
  },
  icons: {
    bank: 'https://platform-static.meliopayments.com/assets/fiserv/Bank.svg',
  },
};

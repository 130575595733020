import { SettingsGroupEnum } from '@/partnersConfig.types';
import { SettingsCardIdEnum } from '../cl/components/SettingsCard/SettingsCard.component';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const wellsFargoConfigBase: PartnerConfigByEnv = {
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/wells-fargo/messages.json',
    tabTitle: 'Pay Bills',
    analytics: {
      blockedEventDestinations: ['Braze'],
    },
    settings: {
      settings: {
        options: [
          {
            type: SettingsGroupEnum.COMPANY,
            items: [
              SettingsCardIdEnum.ProfileSettings,
              SettingsCardIdEnum.CompanySettings,
              SettingsCardIdEnum.WorkflowsSetting,
              SettingsCardIdEnum.NotificationPreferences,
              SettingsCardIdEnum.AccountingSoftwareSync,
            ],
          },
          {
            type: SettingsGroupEnum.PAYMENTS,
            items: [
              SettingsCardIdEnum.PaymentMethodsSetting,
              SettingsCardIdEnum.SubscriptionPlansSettings,
              SettingsCardIdEnum.BillingSetting,
            ],
          },
          {
            type: SettingsGroupEnum.AR,
            items: [
              SettingsCardIdEnum.ReceivingMethodsSettings,
              SettingsCardIdEnum.InvoiceItems,
              SettingsCardIdEnum.InvoiceSettings,
              SettingsCardIdEnum.InvoiceEmailNotifications,
            ],
          },
          {
            type: SettingsGroupEnum.SUPPORT,
            items: [SettingsCardIdEnum.SupportSetting],
          },
        ],
      },
      oAuth: {
        required: true,
        queryParamsMapping: {
          token: 'token',
          target: 'target',
        },
      },
      supportEmail: 'support@wellsfargo.melio.com',
      helpCenterUrl: 'https://wellsfargo.melio.com/hc/en-us',
      expiredSessionTime: 10,
      allowEditCompanyDetails: false,
      supportLegacyOrganizations: false,
      embeddedExperience: {
        enabled: false,
        showFooter: true,
      },
      UseOAuthPopup: {
        flagName: 'platform-use-oauth-popup',
        enabled: true,
      },

      OnboardingModalEnabled: {
        flagName: 'onboarding-modal-enabled',
        enabled: false,
      },
      IsPayDashboardTourEnabled: {
        flagName: 'is-pay-dashboard-tour-enabled',
        enabled: false,
      },
      NpeFirstTimeExperience: {
        flagName: 'npe-first-time-experience',
        enabled: false,
      },
      IsFiservDashboardTourEnabled: {
        flagName: 'is-fiserv-dashboard-tour-enabled',
        enabled: false,
      },
      payment: {
        newPaymentFlow: {
          enabled: true,
        },
      },
    },

    // Service configurations
    services: {
      zendeskKey: 'your-zendesk-key-here',
      zendeskWorkflow: 'Wells Fargo',
      carat: {
        supportedCardProviders: {
          visa: true,
          mastercard: true,
          'american-express': false,
          'diners-club': false,
          discover: false,
          unknown: false,
        },
      },
    },
  },
  dev: {},
  prod: {
    settings: {},
  },
};

export const wellsFargoConfig = extendDefaultConfig(wellsFargoConfigBase);

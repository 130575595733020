import { LocalStorageKeys } from '@melio/local-storage';

import { partnerHostToPartnerName, tenantToPartnerConfiguration } from '@/partnersConfig.types';

const PartnerName = {
  WellsFargo: 'wells-fargo',
  CapitalOne: 'capital-one',
  Clover: 'clover',
  Melio: 'melio',
  Shopify: 'shopify',
  Sbb: 'sbb',
  Fiserv: 'fiserv',
  BuiltTechnologies: 'built-technologies',
  FiservUsBank: 'fiserv_us-bank',
  FiservXdDemo: 'fiserv_xd-demo',
  FiservWafed: 'fiserv_wafed',
  FiservFirstFinancial: 'fiserv_first-financial',
  FiservCitizens: 'fiserv_citizens',
  DeploymentsDemo: 'deployments-demo',
  Paypal: 'paypal',
};

const getBasePath = (uri) => {
  const path = uri.split('/')[1];

  return path || null;
};

export const getPartnerNameFromPath = (uri: string) => {
  const Partners = Object.values(PartnerName).concat(['gusto', 'optum-bank-demo', 'paypal', 'wells-fargo']);

  const basePath = getBasePath(uri);

  return Partners.find((partner) => partner === basePath);
};

export const getPartnerNameFromHost = (hostname?: string) =>
  hostname && Object.keys(partnerHostToPartnerName).includes(hostname) ? partnerHostToPartnerName[hostname] : null;

export const getPartnerNameFromUrlParams = (querystring: string) => {
  const queryParams = new URLSearchParams(querystring);
  const tenant = queryParams.get('tenant');
  if (!tenant) {
    return null;
  }

  return tenantToPartnerConfiguration[tenant];
};

export const getPartnerNameFromLocalStorage = () => {
  return window.sessionStorage.getItem(LocalStorageKeys.partnerName) as string;
};

export const getPartnerNameFromUrl = () => {
  return (getPartnerNameFromPath(location.pathname) || getPartnerNameFromHost(location.hostname)) as string;
};

export const getPartnerName = () => {
  return getPartnerNameFromUrl() || getPartnerNameFromLocalStorage();
};

export const getPartnerUrlPrefixFromUrl = () => {
  const basePath = window.sessionStorage.getItem(LocalStorageKeys.basePath);
  return basePath ? `/${basePath}` : '';
};

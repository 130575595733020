import { useCollaborator, useMelioQueryClient, usePermissions } from '@melio/platform-api';

import { useRefetchPermissions } from './useRefetchPermissions';

type Props = {
  enabled?: boolean;
};

export const usePermissionsProviderData = (props?: Props) => {
  const enabled = typeof props?.enabled === 'boolean' ? props.enabled : false; // for usage with spread operator
  const queryClient = useMelioQueryClient();
  const {
    data: actor,
    isLoading: isLoadingCollaborator,
    error: collaboratorError,
  } = useCollaborator({ id: 'me', enabled });
  const actorRole = actor?.roleUniqueName;

  const {
    data: rolesAndPermissions,
    isFetching,
    isLoading: isLoadingPermissions,
    error: permissionsError,
  } = usePermissions({ enabled });
  const policyRole = rolesAndPermissions?.role;

  const invalidatePermissions = () => {
    if (enabled) {
      setTimeout(() => {
        void queryClient.invalidateQueries('permissions');
      }, 2000);
    }
  };

  useRefetchPermissions({
    actorRole,
    policyRole,
    isFetching,
    onRefetch: invalidatePermissions,
  });

  return {
    permissions: rolesAndPermissions?.permissions,
    role: rolesAndPermissions?.role,
    roles: rolesAndPermissions?.roles,
    isLoading: enabled && (isLoadingCollaborator || isLoadingPermissions),
    error: enabled ? collaboratorError || permissionsError : null,
  };
};

import { merge } from 'lodash';

import { wafedLinks } from '@/links';
import { fiservConfigBase } from './fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const fiservWafedConfigBase: PartnerConfigByEnv = merge<PartnerConfigByEnv>({}, fiservConfigBase, {
  default: {
    links: wafedLinks,
    settings: {
      shouldHideLogoInFooter: true,
      showSupportNumberInFooter: true,
      embeddedExperience: {
        enabled: false,
        showFooter: true,
        showSupportNumberInFooter: true,
        showHeaderLogo: true,
        showLogoInNavbar: true,
      },
      hideOrgSwitcher: true,
      hideLeftNav: true,
      supportPhoneNumber: '800-324-9375',
      newPayExperience: {
        firstTimeExperience: {
          assetType: 'image',
          imageUrl: 'https://platform-static.meliopayments.com/assets/wafed/illustrations/first-time-experience.png',
          mobileImageUrl:
            'https://platform-static.meliopayments.com/assets/wafed/illustrations/first-time-experience.png',
          videoLink: '',
        },
      },
      TermsAndConditions: {
        flagName: 'sign-terms-and-conditions',
        enabled: true,
      },
    },
  },
  dev: {},
  prod: {},
});

export const fiservWafedConfig = extendDefaultConfig(fiservWafedConfigBase);

import { Theme } from '@melio/platform-provider';

export const citizensTheme: Theme = {
  logos: {
    light: 'https://platform-static.meliopayments.com/assets/citizens/FiservLogo.svg',
    dark: 'https://platform-static.meliopayments.com/assets/citizens/FiservLogoDark.svg',
  },
  fonts: {
    primary: 'Roboto,Arial,sans-serif',
  },
  illustrations: {
    'add-card': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/AddCard.svg',
      type: 'image',
    },
    bank: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Bank.svg',
      type: 'image',
    },
    'bank-missing': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/BankMissing.svg',
      type: 'image',
    },
    'bank-success': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/BankSuccess.svg',
      type: 'image',
    },
    blocked: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Blocked.svg',
      type: 'image',
    },
    celebration: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Celebration.svg',
      type: 'image',
    },
    'customer-add': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/CustomerAdd.svg',
      type: 'image',
    },
    declined: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Declined.svg',
      type: 'image',
    },
    expired: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Expired.svg',
      type: 'image',
    },
    fast: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Fast.svg',
      type: 'image',
    },
    'money-success': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/MoneySuccess.svg',
      type: 'image',
    },
    'network-download': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/NetworkDownload.svg',
      type: 'image',
    },
    'network-error': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/NetworkError.svg',
      type: 'image',
    },
    'new-email': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/NewEmail.svg',
      type: 'image',
    },
    'no-items': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/NoItems.svg',
      type: 'image',
    },
    'page-not-found': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/PageNotFound.svg',
      type: 'image',
    },
    'payment-request-link': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/PaymentRequestLink.svg',
      type: 'image',
    },
    'pay-zero': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/PayZero.svg',
      type: 'image',
    },
    pending: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Pending.svg',
      type: 'image',
    },
    processing: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Processing.svg',
      type: 'image',
    },
    sent: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Sent.svg',
      type: 'image',
    },
    'small-business': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/SmallBusiness.svg',
      type: 'image',
    },
    success: {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/Success.svg',
      type: 'image',
    },
    'sync-accounts': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/SyncAccounts.svg',
      type: 'image',
    },
    'sync-user': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/SyncUsers.svg',
      type: 'image',
    },
    'tax-form': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/TaxForm.svg',
      type: 'image',
    },
    'vendor-add': {
      src: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/VendorAdd.svg',
      type: 'image',
    },
  },
  colors: {
    global: {
      brand: {
        100: '#D9EDE6',
        200: '#8AC7B1',
        700: '#008555',
        800: '#005E3C',
        900: '#00492F',
      },
      warning: {
        100: '#FDEFCE',
        700: '#F5B30B',
        900: '#231F20',
      },
      success: {
        100: '#E6F3EE',
        700: '#008555',
        900: '#231F20',
      },
      informative: {
        100: '#E6EDF6',
        700: '#3671B5',
        900: '#231F20',
      },
      critical: {
        100: '#F8EAEA',
        700: '#BC2A2A',
        900: '#231F20',
      },
    },
    semantic: {
      link: {
        primary: {
          rest: '{global.brand.700}',
          hover: '{global.brand.800}',
          pressed: '{global.brand.900}',
        },
      },
      text: {
        brand: {
          rest: '{global.brand.800}',
        },
      },
    },
    component: {
      pill: {
        secondaryCritical: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
        secondarySuccess: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
        secondaryInformative: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
      },
    },
  },
  icons: {
    bank: 'https://platform-static.meliopayments.com/assets/citizens/Bank.svg',
  },
};

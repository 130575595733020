import { Theme } from '@melio/platform-provider';

import { PartnerIcons } from '../../../partnersConfig.types';

export const wellsFargoTheme: Theme = {
  logos: {
    dark: 'https://platform-static.meliopayments.com/assets/wells-fargo/wells-fargo-logo.svg',
    light: 'https://platform-static.meliopayments.com/assets/wells-fargo/wells-fargo-logo-light.svg',
  },
  fonts: {
    primary: 'Wells Fargo Sans, Helvetica, Arial, sans-serif',
  },
  colors: {
    global: {
      brand: {
        100: '#FFF5F5',
        200: '#FFE5E5',
        300: '#FFB3B3',
        400: '#FF8080',
        500: '#FF4D4D',
        600: '#D40000',
        700: '#A10000',
        800: '#6E0000',
        900: '#3B0000',
        1000: '#080000',
      },
      brandSecondary: {
        100: '#FFF5F5',
        200: '#FFE5E5',
        300: '#FFB3B3',
        400: '#FF8080',
        500: '#FF4D4D',
        600: '#D40000',
        700: '#A10000',
        800: '#6E0000',
        900: '#3B0000',
        1000: '#080000',
      },
      warning: {
        100: '#FFFAF2',
        200: '#FFEDD1',
        300: '#FFE2B2',
        400: '#F3D8A5',
        500: '#F0CD8A',
        600: '#F0CD8A',
        700: '#E9B550',
        800: '#DE9C1C',
        900: '#B88023',
        1000: '#634513',
      },
      success: {
        100: '#F3FAFB',
        200: '#D2F3F4',
        300: '#95E3E5',
        400: '#10CBCB',
        500: '#0FB8B8',
        600: '#0DA1A1',
        700: '#0A8080',
        800: '#086D6D',
        900: '#065656',
        1000: '#043E3E',
      },
      informative: {
        100: '#E6F0FE',
        200: '#C7DAFD',
        300: '#A2C1FB',
        400: '#A2C1FB',
        500: '#8FB4FA',
        600: '#5484F8',
        700: '#306AF7',
        800: '#2256D6',
        900: '#0351C0',
        1000: '#0351C0',
      },
      critical: {
        100: '#FFF7F5',
        200: '#FFE7E0',
        300: '#FFB9A3',
        400: '#EA7F71',
        500: '#E66351',
        600: '#E24B37',
        700: '#D5351F',
        800: '#AE2B19',
        900: '#932415',
        1000: '#6B1A0F',
      },
      decorative: { 1: '#E6F0FE', 2: '#FFE0D6', 3: '#FFD0EA', 4: '#D9E1FD', 5: '#C9F8F8' },
    },
    component: {
      tab: {
        default: {
          selected: {
            border: '#D40000',
            label: '{global.neutral.1000}',
          },
          pressed: {
            label: '{global.neutral.1000}',
          },
          hover: {
            label: '{global.neutral.1000}',
          },
        },
      },
    },
  },
};

export const wellsFargoIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/wells-fargo/logo.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/melio-small.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/wells-fargo/logo.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/melio-small.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/wells-fargo/logo.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/error.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/empty.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/empty-vendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/empty.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/empty.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/verify-email.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/tax-summary.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/back-to-partner.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/add-bill.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/start-new-experience.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/try-different-email.icon.svg',
  },
  ConsentImage1: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/consent-1.svg',
  },
  ConsentImage2: {
    small: 'https://platform-static.meliopayments.com/assets/wells-fargo/consent-2.svg',
  },
};

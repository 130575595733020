import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

export enum Events {
  OPENED = 'BLANKET_OPENED',
  CLOSED = 'BLANKET_CLOSED',
  UNMOUNTED = 'BLANKET_UNMOUNTED',
}

export type BlanketEventPayload = { id: string };

export const useDispatchState = (isOpen: boolean, enabled = true) => {
  const uniqueIdRef = useRef(uuid());
  const [isDispatchedEventBlanketInEmbeddedExperience] = useDevFeature(
    FeatureFlags.DispatchedEventBlanketInEmbeddedExperience,
    false
  );

  useEffect(() => {
    if (isDispatchedEventBlanketInEmbeddedExperience && enabled) {
      const id = uniqueIdRef.current;
      if (isOpen) {
        document.dispatchEvent(new CustomEvent<BlanketEventPayload>(Events.OPENED, { detail: { id } }));
      } else {
        document.dispatchEvent(new CustomEvent<BlanketEventPayload>(Events.CLOSED, { detail: { id } }));
      }

      return () => {
        isOpen && document.dispatchEvent(new CustomEvent<BlanketEventPayload>(Events.UNMOUNTED, { detail: { id } }));
      };
    }

    return () => null;
  }, [isOpen, enabled, isDispatchedEventBlanketInEmbeddedExperience]);
};

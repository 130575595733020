import { useCallback, useEffect } from 'react';
import {
  MessageCallback,
  useInitPartnerBridge,
  useListenToEvent as useListenToEventUntyped,
  useSendMessage as useSendMessageUntyped,
} from '@melio/partner-bridge';
import { useFrameSizeChangeHandler } from '@melio/platform-sdk';

import { useRouter } from '@/hooks/router.hooks';
import { useAppRedirectData } from '@/hooks/useAppRedirect.hooks';
import { useNavigationTarget } from '@/utils/useNavigationTarget';
import {
  IncomingPostMessagePayload,
  IncomingPostMessageTypes,
  IncomingPostMessageTypeType,
  MessageCallbackTyped,
  OutgoingPostMessageTypes,
  sendMessageTyped,
} from './partnerBridge.utils.types';
import { useBlanketEvents } from './useBlanketEvents';

export const useListenOnNavigation = (sendMessage: sendMessageTyped) => {
  const { target } = useNavigationTarget();

  useEffect(() => {
    if (target) {
      sendMessage(OutgoingPostMessageTypes.NAVIGATED_TO_TARGET, { target });
    }
  }, [target, sendMessage]);
};

export const useNavigateRequestHandler = () => {
  const { setAppRedirectData } = useAppRedirectData();
  const { goToAppRedirect } = useRouter();

  return useCallback(
    (
      _type: (typeof IncomingPostMessageTypes)['NAVIGATE_REQUEST'],
      payload: IncomingPostMessagePayload['NAVIGATE_REQUEST'],
    ) => {
      setAppRedirectData({ target: payload.target });
      goToAppRedirect();
    },
    [setAppRedirectData, goToAppRedirect],
  );
};

const useOnLoadMessage = (sendMessage: sendMessageTyped) => {
  useEffect(() => {
    sendMessage(OutgoingPostMessageTypes.MELIO_LOADED, {
      status: 'success',
      timestamp: Date.now(),
    });
  }, [sendMessage]);
};

export function useSendMessage() {
  const sendMessage = useSendMessageUntyped();
  return sendMessage as sendMessageTyped;
}
export function usePartnerBridge() {
  useInitPartnerBridge();
  const sendMessage = useSendMessage();
  useListenOnNavigation(sendMessage);
  const navigateRequestHandler = useNavigateRequestHandler();
  useListenToEvent(IncomingPostMessageTypes.NAVIGATE_REQUEST, navigateRequestHandler);
  useBlanketEvents({ sendMessage });
  useFrameSizeChangeHandler();

  useOnLoadMessage(sendMessage);
}

export function useListenToEvent<T extends IncomingPostMessageTypeType>(type: T, cb: MessageCallbackTyped<T>) {
  return useListenToEventUntyped(type, cb as MessageCallback);
}

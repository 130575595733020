import { useListenToEvent } from '@melio/partner-bridge';
import React, { useMemo, useRef } from 'react';

import {
  PlatformAppSDKIncomingMessagePayload as MessagePayload,
  PlatformAppSDKIncomingMessageTypes as MessageTypes,
} from '../partner-messages';

export type ViewportState = {
  rect: Omit<DOMRect, 'toJSON'>;
  viewportHeight: number;
  viewportWidth: number;
  scrollY: number;
  scrollX: number;
};

type ViewportDataAPI = {
  getDocumentBoundingClientRect(
    scrollOverride?: MessagePayload['USER_SCROLL'],
    dimensionsOverrides?: MessagePayload['DIMENSIONS_CHANGED']
  ): ViewportState['rect'];
  getBoundingClientRect(elem: HTMLElement): ViewportState['rect'];
  getScrollY(): number;
  getViewportHeight(dimensionsOverrides?: MessagePayload['DIMENSIONS_CHANGED']): number;
};
const ViewportDataContext = React.createContext<ViewportDataAPI | null>(null);

export function ViewportProvider({ children }: { children: React.ReactNode }) {
  const scrollState = useRef<MessagePayload['USER_SCROLL']>({ scrollX: 0, scrollY: 0 });
  const dimensionsState = useRef<MessagePayload['DIMENSIONS_CHANGED'] | null>(null);

  useListenToEvent<MessagePayload['DIMENSIONS_CHANGED']>(MessageTypes.DIMENSIONS_CHANGED, (_, data) => {
    dimensionsState.current = data;
  });
  useListenToEvent<MessagePayload['USER_SCROLL']>(MessageTypes.USER_SCROLL, (_, data) => {
    scrollState.current = data;
  });

  function calculateRect(scroll: MessagePayload['USER_SCROLL'], dimensions: MessagePayload['DIMENSIONS_CHANGED']) {
    const { scrollX, scrollY } = scroll;
    const { elementDistanceFromTop, elementDistanceFromLeft } = dimensions;
    const { innerHeight, innerWidth } = window;
    const top = elementDistanceFromTop - scrollY;
    const left = elementDistanceFromLeft - scrollX;
    return {
      x: left,
      y: top,
      top,
      bottom: top + innerHeight,
      left,
      height: innerHeight,
      right: left + innerWidth,
      width: innerWidth,
    };
  }

  const api = useMemo(
    () => ({
      getDocumentBoundingClientRect(
        scrollOverride?: MessagePayload['USER_SCROLL'],
        dimensionsOverrides?: MessagePayload['DIMENSIONS_CHANGED']
      ): ViewportState['rect'] {
        const dimensions = dimensionsOverrides || dimensionsState.current;
        return dimensions
          ? calculateRect(scrollOverride || scrollState.current, dimensions)
          : document.body.getBoundingClientRect();
      },
      getBoundingClientRect(elem: HTMLElement): ViewportState['rect'] {
        const nativeRect = elem.getBoundingClientRect();
        if (dimensionsState.current) {
          const frameRect = calculateRect(scrollState.current, dimensionsState.current);

          return {
            x: nativeRect.x + frameRect.x,
            y: nativeRect.y + frameRect.y,
            top: nativeRect.top + frameRect.y,
            left: nativeRect.left + frameRect.x,
            right: nativeRect.right + frameRect.x,
            bottom: nativeRect.bottom + frameRect.y,
            width: nativeRect.width,
            height: nativeRect.height,
          };
        } else {
          return elem.getBoundingClientRect();
        }
      },
      getScrollY(): number {
        return scrollState.current.scrollY;
      },
      getViewportHeight(dimensionsOverrides?: MessagePayload['DIMENSIONS_CHANGED']): number {
        return dimensionsOverrides?.windowHeight || dimensionsState.current?.windowHeight || window.innerHeight;
      },
    }),
    []
  );

  return <ViewportDataContext.Provider value={api}>{children}</ViewportDataContext.Provider>;
}

export function useViewport() {
  const context = React.useContext(ViewportDataContext);
  if (!context) {
    throw new Error('useViewportData must be used within a ViewportDataProvider');
  }
  return context;
}

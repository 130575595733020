import { Button, Container, Divider, Group, GroupProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { PCIComplianceInfo, TermsAndPolicyDisclaimerWidget, useIsMobile } from '@melio/vex-widgets';
import { ReactNode } from 'react';

export type ExternalLayoutContentWidgetCTASectionProps = {
  buttonProps?: { onClick?: () => Promise<void> | void; isDisabled?: boolean; isLoading?: boolean; label: string };
  displayLegalInfo?: boolean;
  legalInfoOverride?: string;
  termsAndPolicyDisclaimer?: ReactNode;
  spacing?: GroupProps['spacing'];
};

export const CTASection = forwardRef<ExternalLayoutContentWidgetCTASectionProps, 'div'>(
  (
    {
      buttonProps,
      legalInfoOverride,
      displayLegalInfo = true,
      termsAndPolicyDisclaimer = <TermsAndPolicyDisclaimerWidget />,
      spacing,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile();

    const content = (
      <Group variant="vertical" spacing={spacing}>
        {buttonProps && <Button {...buttonProps} data-testid="layout-next-button" size="large" isFullWidth />}
        {displayLegalInfo && (
          <Group variant="vertical" spacing="m">
            {termsAndPolicyDisclaimer}
            {!isMobile && <PCIComplianceInfo legalInfoOverride={legalInfoOverride} />}
          </Group>
        )}
      </Group>
    );

    if (isMobile) {
      return (
        <Container
          data-component="ExternalLayoutContent.CTASection"
          {...props}
          style={{ flexShrink: 0 }}
          backgroundColor="white"
          paddingX="none"
          paddingY="none"
          ref={ref}
        >
          <Divider />
          <Container paddingX="s" paddingY="s">
            {content}
          </Container>
        </Container>
      );
    }

    return (
      <Container
        data-component="ExternalLayoutContent.CTASection"
        {...props}
        paddingX="xl"
        paddingTop="xs"
        paddingBottom="l"
        ref={ref}
      >
        {content}
      </Container>
    );
  }
);

CTASection.displayName = 'ExternalLayoutContentWidget.CTASection';

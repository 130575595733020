import { DynamicSettingsType } from './types';

const QUERY_PARAM_NAME = 'dynamicSettings';
const SESSION_STORAGE_PREFIX = 'content-editing-settings';
const storageKeyBySetting: Record<keyof DynamicSettingsType, string> = {
  shouldShowContentKeys: 'show-content-keys',
};

export type Storage = Record<string, string>;

const getStorageKey = (settingKey: keyof DynamicSettingsType) =>
  `${SESSION_STORAGE_PREFIX}-${storageKeyBySetting[settingKey]}`;

const loadSettingsFromStorage = (storage: Storage | null): Partial<DynamicSettingsType> => {
  if (!storage) {
    return {};
  }

  return Object.keys(storageKeyBySetting).reduce((acc, key) => {
    const settingKey = key as keyof DynamicSettingsType;
    const settingValue = storage[getStorageKey(settingKey)];
    if (settingValue !== null && (settingValue === 'true' || settingValue === 'false')) {
      acc[settingKey] = settingValue === 'true';
    }
    return acc;
  }, {} as Partial<DynamicSettingsType>);
};

function parseDynamicSettingsFromQueryParams(dynamicSettingsRaw: string | null): Partial<DynamicSettingsType> {
  if (!dynamicSettingsRaw) {
    return {};
  }

  const keyValuePairs = dynamicSettingsRaw.split(',');
  return keyValuePairs.reduce((acc, pair) => {
    const [key, value] = pair.split(':').map((str) => str.trim());
    if (key && key in storageKeyBySetting && (value === 'true' || value === 'false')) {
      acc[key as keyof DynamicSettingsType] = value === 'true';
    }
    return acc;
  }, {} as Partial<DynamicSettingsType>);
}

const loadSettingsFromQueryParams = (url: string): Partial<DynamicSettingsType> => {
  if (!url) {
    return {};
  }

  const urlObj = new URL(url);
  const urlParams = new URLSearchParams(urlObj.search);
  const dynamicSettingsRaw = urlParams.get(QUERY_PARAM_NAME);
  return parseDynamicSettingsFromQueryParams(dynamicSettingsRaw);
};

export function useDynamicSettings(storage?: Storage, url?: string): Partial<DynamicSettingsType> {
  const settingsFromStorage: Partial<DynamicSettingsType> = storage ? loadSettingsFromStorage(storage) : {};
  const settingsFromQueryParams = url ? loadSettingsFromQueryParams(url) : {};
  return Object.assign(settingsFromStorage, settingsFromQueryParams);
}

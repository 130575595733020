import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { UserPreference, UserPreferencesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQueryClient } from '@tanstack/react-query';

type UseUserPreferencesApiProps = UseCollectionApiProps<
  typeof UserPreferencesApiClient.getUserPreferences,
  UserPreference
>;

export const useUserPreferences = (props: UseUserPreferencesApiProps = {}) => {
  const queryClient = useQueryClient();

  return useCollectionApi<
    typeof UserPreferencesApiClient.getUserPreferences,
    typeof UserPreferencesApiClient.setUserPreferences,
    never,
    never,
    UserPreference
  >({
    ...props,
    queryKey: ['UserPreferencesApi'],
    queryFn: () => UserPreferencesApiClient.getUserPreferences(),
    createFn: UserPreferencesApiClient.setUserPreferences,
    onCreate: (data) => {
      void queryClient.invalidateQueries(['UserPreferencesApi', 'model', data.key]);
    },
  });
};

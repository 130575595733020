import { LinkOverrides } from '../types';

export const sbbLinksOverrides: LinkOverrides = {
  helpCenter: 'https://capitalonebusinessbills.zendesk.com/hc/en-us',
  submitSupportRequest: 'https://capitalonebusinessbills.zendesk.com/hc/en-us/requests/new',
  addMissingEmail: 'https://myaccounts.capitalone.com/Profile',
  failedToCollectSupport:
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702600449052-How-to-handle-unsuccessful-payments',
  'app.settings.SupportSection.accordion.businessBills.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702587129884-What-kind-of-business-bills-can-I-pay-',
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702476344476-Connect-a-credit-card-or-debit-card',
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702492439708-How-to-sync-with-QuickBooks-Online',
  'app.settings.SupportSection.accordion.verifyMicroDeposits.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9726851876508',
  'app.settings.SupportSection.accordion.payBill.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702503832732-How-to-make-a-payment-with-Pay-Business-Bills',
  'app.settings.SupportSection.accordion.deliveryTimelines.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702497316508-When-will-my-payment-arrive-',
  'app.settings.SupportSection.accordion.vendors.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702550370588-How-to-add-a-vendor',
  'widgets.companySettings.header.sectionBanner.linkHref': 'tel:888-755-2172',
  'activities.checkProtectionModal.securing.link':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702529938076-Paying-with-checks-what-vendors-receive',
  'activities.multiAccountBanner.cta.href':
    'https://capitalonebusinessbills.melio.com/hc/en-us/articles/17882994463260-How-to-add-multiple-business-accounts-to-pay-business-bills',
  undepositedCheckLearnMore:
    'https://capitalonebusinessbills.melio.com/hc/en-us/articles/18233329706396-How-to-void-an-undeposited-vendor-check',
  'app.settings.SupportSection.accordion.addAllAccounts.links.link1.href': 'https://myaccounts.capitalone.com',
  'app.settings.SupportSection.accordion.addAllAccounts.links.link2.href':
    'https://capitalonebusinessbills.melio.com/hc/en-us/articles/17882994463260-How-to-add-multiple-business-accounts-to-pay-business-bills',
  missingEmailOnboardingErrorImage:
    'https://platform-static.meliopayments.com/assets/sbb/illustrations/sbb_profile_personal_email.png',
};

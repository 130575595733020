import { merge } from 'lodash';
import React, { useContext, useMemo } from 'react';

type PlatformDSConfig = { isFullHeightMode?: boolean };

const PlatformDSContext = React.createContext<PlatformDSConfig>({} as PlatformDSConfig);

type LayoutConfigProviderProps = {
  config?: PlatformDSConfig;
};

const defaultConfig = { isFullHeightMode: false };
export const PlatformDSProvider: React.FC<LayoutConfigProviderProps> = ({ children, config = {} }) => {
  const value = useMemo(() => merge(defaultConfig, config), [config]);
  return <PlatformDSContext.Provider value={value}>{children}</PlatformDSContext.Provider>;
};

export const usePlatformDSConfig = () => useContext<PlatformDSConfig>(PlatformDSContext);

import { useEffect, useRef } from 'react';
import { BlanketEventPayload, Events } from '@melio/platform-ds';
import { useViewport } from '@melio/platform-sdk';

import { OutgoingPostMessageTypes, sendMessageTyped } from './partnerBridge.utils.types';

type BlanketEvent = CustomEvent<BlanketEventPayload>;

export const useBlanketEvents = ({ sendMessage }: { sendMessage: sendMessageTyped }) => {
  const { getViewportHeight } = useViewport();
  const openBlanketsRef = useRef<{ [key: string]: boolean }>({});

  useEffect(() => {
    const handleOpenEvent = (event: BlanketEvent) => {
      const parentViewPortHeight = getViewportHeight();
      document.body.style.minHeight = `${parentViewPortHeight}px`;
      document.body.style.maxHeight = `${parentViewPortHeight}px`;
      sendMessage(OutgoingPostMessageTypes.SCROLL_TO, { relativeScrollY: 0, relativeScrollX: 0, preventScroll: true });
      openBlanketsRef.current[event.detail.id] = true;
    };

    const handleCloseEvent = (event: BlanketEvent) => {
      const newCurrentOpenBlanketsRef = { ...openBlanketsRef.current };
      delete newCurrentOpenBlanketsRef[event.detail.id];
      openBlanketsRef.current = newCurrentOpenBlanketsRef;
      if (Object.keys(openBlanketsRef.current).length === 0) {
        document.body.style.removeProperty('min-height');
        document.body.style.removeProperty('max-height');
        sendMessage(OutgoingPostMessageTypes.SCROLL_TO, { preventScroll: false });
      }
    };

    document.addEventListener(Events.OPENED, handleOpenEvent as EventListener);
    document.addEventListener(Events.CLOSED, handleCloseEvent as EventListener);
    document.addEventListener(Events.UNMOUNTED, handleCloseEvent as EventListener);

    return () => {
      document.removeEventListener(Events.OPENED, handleOpenEvent as EventListener);
      document.removeEventListener(Events.CLOSED, handleCloseEvent as EventListener);
      document.removeEventListener(Events.UNMOUNTED, handleCloseEvent as EventListener);
    };
  }, [getViewportHeight, sendMessage]);
};

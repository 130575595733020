// eslint-disable-next-line import/no-extraneous-dependencies
import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Renderers } from './types';

export const renderers: Renderers = {
  semiBold: (...chunks: ReactNode[]) => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
  bold: (...chunks: ReactNode[]) => <strong>{chunks}</strong>,
  mark: (...chunks: ReactNode[]) => <mark>{chunks}</mark>,
  emphasize: (...chunks: ReactNode[]) => <em className="emphasize">{chunks}</em>,
  success: (...chunks: ReactNode[]) => (
    <Box as="span" color="global.success.700">
      {chunks}
    </Box>
  ),
  u: (...chunks: ReactNode[]) => <u>{chunks}</u>,
  br: <br />,
};

import { useGuestPayment, usePaymentUpgrade as usePaymentUpgradeApi } from '@melio/platform-api';

import { getIsPaymentFailed, getIsPaymentStateFinal, getUpgradeTypeOfPayment } from './utils';

export const usePaymentUpgrade = ({ paymentId }: { paymentId: string }) => {
  const {
    data: payment,
    error: paymentError,
    isFetching: isPaymentFetching,
    isLoading: isPaymentLoading,
    refetch: refetchGuestPayment,
  } = useGuestPayment(paymentId);

  const isPaymentFailed = getIsPaymentFailed(payment);
  const isPaymentStateFinal = getIsPaymentStateFinal(payment);
  const isPaymentAlreadyExpeditedBefore = Boolean(getUpgradeTypeOfPayment(payment));

  const {
    postPaymentUpgrade,
    paymentUpgradeOffers,
    isPostPaymentUpgradeLoading,
    isPaymentUpgradeOffersLoading,
    paymentUpgradeOffersError,
    postPaymentUpgradeError,
  } = usePaymentUpgradeApi({
    paymentId,
    isPaymentUpgradeOffersFetchingEnabled: Boolean(
      payment && !isPaymentStateFinal && !isPaymentFailed && !isPaymentAlreadyExpeditedBefore
    ),
  });

  return {
    payment,
    getGuestPaymentError: paymentError,
    isPaymentFetching,
    isPaymentUpgradeSubmitting: isPostPaymentUpgradeLoading,
    isPaymentUpgradeLoading: isPaymentLoading || isPaymentUpgradeOffersLoading,
    error: paymentError || paymentUpgradeOffersError || postPaymentUpgradeError,
    paymentUpgradeOffers: paymentUpgradeOffers || [],
    refetchGuestPayment,
    postPaymentUpgrade,
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { LocalStorageKeys } from '@melio/local-storage';
import { useFundingSources } from '@melio/platform-api';
// eslint-disable-next-line no-restricted-imports
import { ActionTokenTarget, ExchangeIdsRequestDirectionEnum, ExternalTarget } from '@melio/platform-api-axios-client';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useSystemMessage } from '@melio/platform-utils';

import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { usePlatformIntl } from '@/translations/Intl';
import { AppRedirectData } from '@/types/app.types';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { mapTargetToTargetAction } from '@/utils/target.utils';
import { usePartnerConfig } from './partners';
import { useRouter } from './router.hooks';
import { useExchangeIds } from './useExchangeIds';

export enum RedirectType {
  Internal = 'internal',
  External = 'external',
}

type RedirectLinkData = {
  link: string;
  type: RedirectType;
};

export const useAppRedirectData = () => {
  const localStorage = usePartnerLocalStorage();

  const setAppRedirectData = React.useCallback(
    (data: AppRedirectData) => {
      localStorage.setObject(LocalStorageKeys.appRedirectData, data);
    },
    [localStorage],
  );

  const getAppRedirectData = React.useCallback(
    () => localStorage.getObject<AppRedirectData>(LocalStorageKeys.appRedirectData) || {},
    [localStorage],
  );

  const removeAppRedirectData = React.useCallback(() => {
    localStorage.removeItem(LocalStorageKeys.appRedirectData);
  }, [localStorage]);

  return { setAppRedirectData, getAppRedirectData, removeAppRedirectData };
};

export const useAppRedirectLink = () => {
  const [redirectLink, setRedirectLink] = React.useState<string>('');
  const [redirectType, setRedirectType] = React.useState<RedirectType>(RedirectType.Internal);
  const { formatMessage } = usePlatformIntl();
  const router = useRouter();

  const { data: fundingSources, isLoading: isFundingSourcesLoading } = useFundingSources();
  const { exchangeIds } = useExchangeIds();
  const { showMessage } = useSystemMessage();
  const { getAppRedirectData, removeAppRedirectData } = useAppRedirectData();
  const {
    partnerConfig: {
      config: {
        settings: { externalIdsPassedForNavigation },
      },
    },
  } = usePartnerConfig();
  const [shouldGoToJustPay] = useDevFeature<boolean>(FeatureFlags.JustPay, false);

  const getRedirectLink = React.useCallback(
    async (
      redirectData: AppRedirectData,
    ): Promise<Pick<RedirectLinkData, 'link'> & { type?: RedirectLinkData['type'] }> => {
      let { targetAction, targetId, isFirstTimeLogin, redirectUrl, target, isAccountingFirm } = redirectData;
      const defaultLink = isAccountingFirm ? router.generateAccountantsDashboardLink() : router.generatePayTabLink();

      if (target?.startsWith('/')) {
        const actionFromTarget = mapTargetToTargetAction(target);
        targetAction = actionFromTarget.targetAction as ActionTokenTarget | ExternalTarget;
        targetId = actionFromTarget.targetId;
        if ('redirectUrl' in actionFromTarget && actionFromTarget.redirectUrl) {
          redirectUrl = actionFromTarget.redirectUrl;
        }
        if (targetId && externalIdsPassedForNavigation) {
          const idsToExchange = targetId.includes(',') ? targetId.split(',') : [targetId];

          const entityType = actionFromTarget.entityType;
          const prefix = entityType ? `${entityType}_` : undefined;

          const idsToExchangeWithPrefix = idsToExchange.map((id) => {
            if (prefix && !id.startsWith(prefix)) {
              return `${prefix}${id}`;
            } else {
              return id;
            }
          });

          try {
            const response = await exchangeIds(
              ExchangeIdsRequestDirectionEnum.ExternalToInternal,
              idsToExchangeWithPrefix,
            );
            targetId = idsToExchangeWithPrefix.map((id) => response[id] || id).join(',');
          } catch (error) {
            /* empty */
          }
        }
      }
      if (redirectUrl) {
        return { link: router.generateRedirectUrl(redirectUrl) };
      } else if (!targetAction) {
        return {
          link: shouldGoToJustPay && isFirstTimeLogin ? router.generateJustPayLink() : defaultLink,
        };
      } else {
        switch (targetAction as string) {
          case 'onboarding':
            return { link: router.generateContextualOnboardingLink() };
          case 'viewArInvoices':
            return { link: router.generateArLink() };
          case 'justPay':
            return { link: router.generateJustPayLink() };
          case 'microDeposit': {
            if (targetId && fundingSources?.find((it) => it.id === targetId)) {
              return {
                link: router.generateSettingsPaymentMethodsMicroDepositsLink(targetId),
              };
            } else {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.microDeposit.error'),
              });
              return { link: defaultLink };
            }
          }
          case 'scheduledPayment': {
            if (!targetId) {
              return {
                link: router.generatePayDashboardTabLink(PayDashboardTabs.Scheduled),
              };
            } else {
              return {
                link: router.generatePayDashboardTabLink(PayDashboardTabs.Scheduled, targetId),
              };
            }
          }
          case 'paidPayment': {
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.paidPayment.error'),
              });
              return { link: defaultLink };
            } else {
              return {
                link: router.generatePayDashboardTabLink(PayDashboardTabs.Paid, targetId),
              };
            }
          }
          case 'viewPayment':
            return {
              link: router.generateViewPaymentLink(targetId, PayDashboardTabs.Paid),
            };
          case 'viewSettings':
            return { link: router.generateSettingsTabLink(targetId) };
          case 'viewVendors':
            return { link: router.generateViewVendorLink(targetId) };
          case 'schedulePayment':
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.microDeposit.error'),
              });
              return { link: defaultLink };
            } else {
              return { link: router.generateSchedulePaymentLink(targetId) };
            }
          case 'editPayment':
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.editPayment.error'),
              });
              return { link: defaultLink };
            } else {
              return { link: router.generateEditPaymentLink(targetId) };
            }
          case 'scheduleBatchPayments':
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.scheduleBatchPayments.error'),
              });
              return { link: defaultLink };
            } else {
              return { link: router.generateBatchPaymentsLink(targetId) };
            }
          case 'viewBill': {
            return {
              link: router.generateViewBillLink(targetId),
            };
          }
          case 'viewScheduledPayment': {
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.viewScheduledPayment.error'),
              });
              return { link: defaultLink };
            } else {
              return {
                link: router.generateViewPaymentLink(targetId, PayDashboardTabs.Scheduled),
              };
            }
          }
          case 'viewPaidPayment': {
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.viewPaidPayment.error'),
              });
              return { link: defaultLink };
            } else {
              return {
                link: router.generateViewPaymentLink(targetId, PayDashboardTabs.Paid),
              };
            }
          }
          case 'viewScannedInvoice': {
            if (!targetId) {
              showMessage({
                type: 'informative',
                title: formatMessage('app.deepLinksActions.viewScannedInvoice.error'),
              });
              return { link: defaultLink };
            } else {
              return {
                link: router.generateReviewScannedInvoiceLink({ scannedInvoiceId: targetId }),
              };
            }
          }
          default:
            return { link: defaultLink };
        }
      }
    },
    [router, shouldGoToJustPay, fundingSources],
  );

  React.useEffect(() => {
    const fetchRedirectLink = async () => {
      if (!isFundingSourcesLoading) {
        const appRedirectData = getAppRedirectData();
        const { link, type } = await getRedirectLink(appRedirectData);
        setRedirectLink(link);
        if (type) {
          setRedirectType(type);
        }
      }
    };

    fetchRedirectLink();
  }, [isFundingSourcesLoading]);

  React.useEffect(() => removeAppRedirectData, []);

  return { link: redirectLink, type: redirectType, isLoading: isFundingSourcesLoading };
};

import { DeliveryMethodType, PaymentUpgradeOfferOption, VexGuestPaymentResponse } from '@melio/platform-api';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

type PaymentUpgradeIndexScreenProps = {
  payment?: VexGuestPaymentResponse;
  paymentUpgradeOffers: Array<PaymentUpgradeOfferOption>;
};

export const UpgradePaymentFromCheckIndexScreen: FC<PaymentUpgradeIndexScreenProps> = ({
  payment,
  paymentUpgradeOffers,
}) => {
  const hasUpgradeOffers = paymentUpgradeOffers.length > 0;
  const isPaymentCheck = payment?.deliveryMethod?.type === DeliveryMethodType.PaperCheck;

  if (isPaymentCheck && hasUpgradeOffers) {
    return <Navigate to="bank-account" replace />;
  }
  return <Navigate to={`upgrade-payment/${payment?.deliveryMethod?.id as string}/index`} />;
};

import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, Illustration, Image, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { ErrorPageTheme } from './ErrorPage.component.theme';

type ButtonConfig = {
  text: string;
  isLoading?: boolean;
  onClick: VoidFunction;
};

export type ErrorPageParams = {
  icon?: string;
  illustration?: string;
  title: string;
  message?: string;
  dividerText?: string;
  primaryButton?: ButtonConfig;
  secondaryButton?: ButtonConfig;
  'data-testid'?: string;
  bottomImage?: JSX.Element;
};

export const ErrorPage = ({
  icon,
  illustration,
  title,
  message,
  dividerText,
  primaryButton,
  secondaryButton,
  'data-testid': dataTestId,
  bottomImage,
}: ErrorPageParams) => {
  const hasBothButtons = Boolean(primaryButton && secondaryButton);
  const styles = useAppTheme(ErrorPageTheme, {});

  return (
    <Box sx={styles['container']} data-testid={dataTestId} justifyContent="center">
      <Container justifyContent="center">
        {illustration || !icon ? (
          <Illustration size="large" type={illustration ?? 'expired'} />
        ) : (
          <Image width="auto" height="auto" src={icon} alt="error" alignSelf={'center'} />
        )}
      </Container>
      <Box sx={styles['textContainer']}>
        <Text data-testid="error-page-title" textStyle="heading1Semi">
          {title}
        </Text>
        <Text data-testid="error-page-message" textStyle="body3">
          {message}
        </Text>
        {bottomImage}
      </Box>
      <Box>
        {primaryButton && (
          <Button
            variant="primary"
            onClick={primaryButton.onClick}
            isLoading={primaryButton.isLoading}
            isFullWidth={hasBothButtons}
            label={primaryButton.text}
            data-testid="error-page-primary-button"
          />
        )}
        {hasBothButtons && dividerText && (
          <Box my={'16px'}>
            <Divider label={dividerText} />
          </Box>
        )}
        {secondaryButton && (
          <Button
            variant="tertiary"
            onClick={secondaryButton.onClick}
            isLoading={secondaryButton.isLoading}
            label={secondaryButton.text}
          />
        )}
      </Box>
    </Box>
  );
};

import { MelioIcons } from '@/cl/icons/melio';

export const PayPalIcons = {
  ...MelioIcons,
  Favicon: '/partners/paypal/assets/favicon.png',
  SmallLogoIcon: undefined,
  EmptyBillsIcon: {},
  LogoIcon: {
    small: '/partners/paypal/assets/paypal-logo-desktop.svg',
    extraSmall: '/partners/paypal/assets/paypal-logo-mobile.svg',
  },
};

import { useCallback, useEffect, useState } from 'react';
import { useHealth } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

// Convert local time to UTC event when a user changed his clock manually!
export const useConvertLocalTimeToCorrectTime = () => {
  const [isConvertToCorrectTimeAuthEnabled] = useDevFeature(FeatureFlags.IsConvertToCorrectTimeAuthEnabled, false);
  const [initialLocalTime, setInitialLocalTime] = useState<number>();
  const { isFetched, data, error } = useHealth({ enabled: isConvertToCorrectTimeAuthEnabled });

  useEffect(() => {
    if (isFetched) {
      setInitialLocalTime(Date.now());
    }
  }, [isFetched]);

  const getCorrectDatetimeNow = useCallback(() => {
    const initialServerTime = data?.time;
    if (!initialLocalTime || !initialServerTime || error || !isConvertToCorrectTimeAuthEnabled) {
      return Date.now();
    }

    const passedTime = Date.now() - initialLocalTime;
    return initialServerTime + passedTime;
  }, [data, error, initialLocalTime, isConvertToCorrectTimeAuthEnabled]);

  return {
    isFetched: isFetched && !!initialLocalTime,
    getCorrectDatetimeNow: getCorrectDatetimeNow,
  };
};

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { UseMutateAsyncFunction, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { LocalStorageKeys } from '@melio/local-storage';
import {
  CapitalOneProviderAllowedPartners,
  PartnerName,
  PostAuthProviderCallbackCapitalOneRequest,
  PostAuthProviderCallbackCloverRequest,
  PostAuthProviderCallbackFiservRequest,
  PostAuthProviderCallbackMelioRequest,
  PostAuthProviderCallbackPaypalRequest,
  PostAuthProviderCallbackStandardRequest,
  PostAuthResponseData,
  PostAuthSsoRequest,
  PostExchangeTokenToAccessTokenRequestResponse,
  PostResolveEmailVerificationCodeRequest,
  PostSendEmailVerificationCodeRequest,
  SsoAuth,
} from '@melio/platform-api';

import {
  authProviderCallbackCapitalOne,
  authProviderCallbackClover,
  authProviderCallbackFiserv,
  authProviderCallbackMelio,
  authProviderCallbackPaypalProvider,
  authProviderCallbackStandardProvider,
  authProviderLogoutMelio,
  getAuthTokenFromActionToken,
  oAuthRegister,
  resolveVerificationEmailCode,
  sendVerificationEmailCode,
} from '@/api/auth.api';
import { getAuthTokenFromActionTokenQueryKey } from '@/queries/reactQueryKeys';
import { usePartnerLocalStorage } from './partners/usePartnerLocalStorage';
import { usePartnerConfig } from './partners';

export type CapitalOneAuthMutation = UseMutateAsyncFunction<
  PostExchangeTokenToAccessTokenRequestResponse['data'],
  unknown,
  PostAuthProviderCallbackCapitalOneRequest
>;
export type CloverAuthMutation = UseMutateAsyncFunction<
  PostExchangeTokenToAccessTokenRequestResponse['data'],
  unknown,
  PostAuthProviderCallbackCloverRequest
>;
export type MelioAuthMutation = UseMutateAsyncFunction<
  PostExchangeTokenToAccessTokenRequestResponse['data'],
  unknown,
  PostAuthProviderCallbackMelioRequest
>;
export type FiservAuthMutation = UseMutateAsyncFunction<
  PostExchangeTokenToAccessTokenRequestResponse['data'],
  unknown,
  PostAuthProviderCallbackFiservRequest
>;
export type StandardAuthMutation = UseMutateAsyncFunction<
  PostExchangeTokenToAccessTokenRequestResponse['data'],
  unknown,
  PostAuthProviderCallbackMelioRequest & { partnerName: string }
>;
export type PaypalAuthMutation = UseMutateAsyncFunction<
  PostExchangeTokenToAccessTokenRequestResponse['data'],
  unknown,
  PostAuthProviderCallbackPaypalRequest
>;

type UseOAuthCallbackReturnType<T extends PartnerName> = {
  mutate: T extends typeof PartnerName.CapitalOne
    ? CapitalOneAuthMutation
    : T extends typeof PartnerName.Clover
    ? CloverAuthMutation
    : T extends typeof PartnerName.Melio
    ? MelioAuthMutation
    : undefined;
  data: PostExchangeTokenToAccessTokenRequestResponse['data'] | undefined;
  isLoading: boolean;
  isError: boolean;
  error?: AxiosError;
};

export const isFiservAllowedPartner = (partnerName?: PartnerName): boolean =>
  partnerName?.startsWith('fiserv') ?? false;

export const useOAuthCallback = <T extends PartnerName>({
  partnerName,
  onSuccess,
  onError,
}: {
  partnerName?: T;
  onSuccess: (data: PostExchangeTokenToAccessTokenRequestResponse['data']) => void;
  onError: (error: AxiosError<unknown, unknown>) => void;
}): UseOAuthCallbackReturnType<T> => {
  const capitalOneAuthMutation = useMutation(
    ({
      token,
      target,
      targetId,
      entryPoint,
      orgId,
      partnerName,
      userRole,
    }: PostAuthProviderCallbackCapitalOneRequest) =>
      authProviderCallbackCapitalOne({
        token,
        orgId,
        target,
        targetId,
        entryPoint,
        partnerName:
          partnerName === PartnerName.CapitalOne
            ? CapitalOneProviderAllowedPartners.CapitalOne
            : partnerName === PartnerName.Sbb
            ? CapitalOneProviderAllowedPartners.Sbb
            : undefined,
        userRole,
      }),
    { onSuccess, onError },
  );

  const cloverAuthMutation = useMutation(
    ({ token, orgId, target, userId, targetId, entryPoint }: PostAuthProviderCallbackCloverRequest) =>
      authProviderCallbackClover({ token, userId, orgId, target, targetId, entryPoint }),
    { onSuccess, onError },
  );
  const melioAuthMutation = useMutation(
    ({ token, orgId, target, targetId, entryPoint }: PostAuthProviderCallbackMelioRequest) =>
      authProviderCallbackMelio({ token, orgId, target, targetId, entryPoint }),
    { onSuccess, onError },
  );

  const fiservAuthMutation = useMutation(
    ({ token }: PostAuthProviderCallbackFiservRequest) =>
      authProviderCallbackFiserv({
        token,
        partnerName: isFiservAllowedPartner(partnerName) ? partnerName : undefined,
      }),
    { onSuccess, onError },
  );

  const standardProviderAuthMutation = useMutation(
    ({ token, partnerName, target, targetId, entryPoint }: PostAuthProviderCallbackStandardRequest) =>
      authProviderCallbackStandardProvider({ token, target, targetId, entryPoint, partnerName }),
    { onSuccess, onError },
  );

  const paypalProviderAuthMutation = useMutation(
    ({ token, target, targetId, entryPoint }: PostAuthProviderCallbackPaypalRequest) =>
      authProviderCallbackPaypalProvider({ token, target, targetId, entryPoint }),
    { onSuccess, onError },
  );

  const mutation =
    partnerName === PartnerName.Clover
      ? cloverAuthMutation
      : partnerName === PartnerName.CapitalOne || partnerName === PartnerName.Sbb
      ? capitalOneAuthMutation
      : partnerName === PartnerName.Melio
      ? melioAuthMutation
      : partnerName === PartnerName.Fiserv || isFiservAllowedPartner(partnerName)
      ? fiservAuthMutation
      : partnerName === PartnerName.Paypal
      ? paypalProviderAuthMutation
      : standardProviderAuthMutation;

  return {
    mutate: mutation?.mutateAsync,
    data: mutation?.data,
    isLoading: mutation?.isLoading || false,
    isError: mutation?.isError || false,
    error: mutation?.error || undefined,
  } as UseOAuthCallbackReturnType<T>;
};

export const useLogoutPartner = (partnerName?: PartnerName) => {
  const melioAuthMutation = useMutation(authProviderLogoutMelio);

  const mutation = partnerName === PartnerName.Melio ? melioAuthMutation : null;

  return {
    logout: mutation?.mutateAsync,
    data: mutation?.data,
    isLoading: mutation?.isLoading || false,
    isError: mutation?.isError || false,
    error: mutation?.error || undefined,
  };
};

export const useOAuthRegister = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: SsoAuth) => Promise<void>;
  onError: (error: AxiosError<unknown, unknown>) => void;
}) => {
  const { mutateAsync, ...rest } = useMutation<SsoAuth, AxiosError, PostAuthSsoRequest>(
    ({ partnerName, providerData, accountInfo, selectedOrgId, userConsent }: PostAuthSsoRequest) =>
      oAuthRegister({ partnerName, providerData, accountInfo, selectedOrgId, userConsent }),
    { onSuccess, onError },
  );
  return {
    oAuthRegister: mutateAsync,
    ...rest,
  };
};

export const useAuthSendEmailVerification = () => {
  const { mutateAsync: sendMutateAsync, ...rest } = useMutation(
    ({ sendParams }: { sendParams: PostSendEmailVerificationCodeRequest }) => {
      return sendVerificationEmailCode(sendParams);
    },
  );
  return {
    sendVerificationCode: (sendParams: PostSendEmailVerificationCodeRequest) => {
      return sendMutateAsync({
        sendParams,
      });
    },
    ...rest,
  };
};

export const useAuthResolveEmailVerification = () => {
  const { mutateAsync, ...rest } = useMutation(
    ({ resolveParams }: { resolveParams: PostResolveEmailVerificationCodeRequest }) => {
      return resolveVerificationEmailCode(resolveParams);
    },
  );

  return {
    resolveCode: (resolveParams: PostResolveEmailVerificationCodeRequest) => {
      return mutateAsync({
        resolveParams,
      });
    },
    ...rest,
  };
};

export const useAuthTokenFromActionToken = (token: string) => {
  return useQuery<{}, {}, PostAuthResponseData>(getAuthTokenFromActionTokenQueryKey(token), () =>
    getAuthTokenFromActionToken(token),
  );
};

export const useOAuthQueryParams = () => {
  const { partnerConfig } = usePartnerConfig();
  const [queryParams] = useSearchParams();
  const localStorage = usePartnerLocalStorage();

  const {
    token: tokenQueryParam = '',
    orgId: orgIdQueryParam = '',
    userId: userIdQueryParam = '',
    target: targetQueryParam = '',
    targetId: targetIdQueryParam = '',
    entryPoint: entryPointQueryParam = '',
    redirectUrl: redirectUrlQueryParam = '',
    userRole: userRoleQueryParam = '',
    backButtonReferenceId: backButtonReferenceIdQueryParam = '',
  } = partnerConfig.oAuth.required ? partnerConfig.oAuth.queryParamsMapping : {};

  const token = queryParams.get(tokenQueryParam) || '';
  const orgId = queryParams.get(orgIdQueryParam) || '';
  const userId = queryParams.get(userIdQueryParam) || '';
  const targetQuery = queryParams.get(targetQueryParam);
  const target = targetQuery && targetQuery.toLowerCase() !== 'default' ? targetQuery : '';
  const targetIdQuery = queryParams.get(targetIdQueryParam) || '';
  const entryPoint = queryParams.get(entryPointQueryParam) || '';
  const redirectUrl = queryParams.get(redirectUrlQueryParam) || '';
  const userRole = queryParams.get(userRoleQueryParam) || '';
  const backButtonReferenceId = queryParams.get(backButtonReferenceIdQueryParam) || '';
  const sessionData = queryParams.get('sessionData');

  useEffect(() => {
    if (
      backButtonReferenceId &&
      backButtonReferenceId !== localStorage.getItem(LocalStorageKeys.backButtonReferenceId)
    ) {
      localStorage.setItem(LocalStorageKeys.backButtonReferenceId, backButtonReferenceId);
    }
  }, [backButtonReferenceId, localStorage]);

  return {
    token,
    orgId,
    userId,
    targetQuery,
    target,
    targetIdQuery,
    entryPoint,
    redirectUrl,
    userRole,
    backButtonReferenceId,
    sessionData,
  };
};

import { APActivitiesOutgoingMessagePayload, APActivitiesOutgoingMessageTypes } from '@melio/ap-activities';
import {
  PlatformAppSDKIncomingMessagePayload,
  PlatformAppSDKIncomingMessageTypes,
  PlatformAppSDKOutgoingMessagePayload,
  PlatformAppSDKOutgoingMessageTypes,
} from '@melio/platform-sdk';

import { OnboardingErrorReason } from '@/utils/onboarding.utils';

export const OutgoingPostMessageTypes = {
  USER_ACTIVE_PING: 'USER_ACTIVE_PING',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  NAVIGATED_TO_TARGET: 'NAVIGATED_TO_TARGET',
  MELIO_LOADED: 'MELIO_LOADED',
  PARTNER_ACTION_REQUIRED: 'PARTNER_ACTION_REQUIRED',
  ...APActivitiesOutgoingMessageTypes,
  ...PlatformAppSDKOutgoingMessageTypes,
} as const;

export const IncomingPostMessageTypes = {
  NAVIGATE_REQUEST: 'NAVIGATE_REQUEST',
  ...PlatformAppSDKIncomingMessageTypes,
} as const;

export type OutgoingPostMessagePayload = {
  USER_ACTIVE_PING: {};
  SESSION_EXPIRED: {};
  AUTHENTICATION_ERROR: { reason: OnboardingErrorReason; missingFields?: string[]; tenant?: string };
  NAVIGATED_TO_TARGET: { target: string };
  MELIO_LOADED: {
    status: 'success';
    timestamp: number;
  };
  PARTNER_ACTION_REQUIRED: {
    data: {
      requiredAction: string;
      rawData: string;
      returnUrl: string;
    };
  };
} & APActivitiesOutgoingMessagePayload &
  PlatformAppSDKOutgoingMessagePayload;

export type IncomingPostMessagePayload = {
  NAVIGATE_REQUEST: { target: string };
} & PlatformAppSDKIncomingMessagePayload;

export type OutgoingPostMessageTypeType = (typeof OutgoingPostMessageTypes)[keyof typeof OutgoingPostMessageTypes];
export type IncomingPostMessageTypeType = (typeof IncomingPostMessageTypes)[keyof typeof IncomingPostMessageTypes];

export type sendMessageTyped = <T extends OutgoingPostMessageTypeType>(
  type: T,
  payload: OutgoingPostMessagePayload[T],
) => void;
export type MessageCallbackTyped<T extends IncomingPostMessageTypeType> = (
  type: T,
  payload: IncomingPostMessagePayload[T],
) => void;

import { Button, Card, ExternalLayout, Group, Typography, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, PageTitle } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { FooterWidget } from '@melio/vex-widgets';

import { CollectBusienssDetailsForm, CollectBusinessDetailsFormModel } from '../components';

type CollectDetailsScreenProps = {
  businessId: string;
  businessLegalName: string;
  businessEmail: string;
  onSubmit: (formData: CollectBusinessDetailsFormModel) => Promise<void>;
  isLoading: boolean;
};

export const CollectBusinessDetailsScreen = forwardRef<CollectDetailsScreenProps>(
  ({ businessLegalName, businessEmail, onSubmit, isLoading }, ref) => {
    const { submitButtonProps, onSubmissionStateChange } =
      useFormSubmissionController<CollectBusinessDetailsFormModel>();
    const { formatMessage } = useMelioIntl();
    usePageView('CollectBusinessDetails');

    return (
      <ExternalLayout
        ref={ref}
        content={
          <Card>
            <Group width="full" spacing="m" variant="vertical">
              <Group width="full" spacing="xs" variant="vertical">
                <PageTitle textStyle="heading3Semi">
                  {formatMessage('vex.activities.collectBusinessDetails.title')}
                </PageTitle>
                <Typography.Paragraph
                  content={formatMessage('vex.activities.collectBusinessDetails.description')}
                  size="large"
                />
              </Group>
              <CollectBusienssDetailsForm
                onSubmit={onSubmit}
                defaultValues={{
                  email: businessEmail,
                  businessName: businessLegalName,
                }}
                onSubmissionStateChange={onSubmissionStateChange}
              />
              <Button
                {...submitButtonProps}
                isLoading={isLoading}
                label={formatMessage('vex.activities.collectBusinessDetails.submitButton')}
              />
            </Group>
          </Card>
        }
        footer={<FooterWidget />}
      />
    );
  }
);

CollectBusinessDetailsScreen.displayName = 'CollectBusinessDetailsScreen';

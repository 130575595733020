import { Card, Container, Group, useBreakpointValue } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { PCIComplianceInfo, useIsMobile } from '@melio/vex-widgets';
import { ReactNode } from 'react';

import {
  components,
  ExternalLayoutContentWidgetCTASectionProps,
  ExternalLayoutContentWidgetFormProps,
} from './components';

export type ExternalLayoutContentWidgetProps = {
  header?: { backButtonProps?: { isDisabled?: boolean; onClick: VoidFunction }; title: ReactNode };
  form?: ExternalLayoutContentWidgetFormProps;
  ctaSection?: ExternalLayoutContentWidgetCTASectionProps;
  footer?: React.ReactElement;
  children?: never;
};

// This is a copy of ExternalLayoutContentWidget with several enhancements to support sticky CTA behavior.
// It’s a quick fix, but the optimal approach is to update the ExternalLayout component at the design system level.
const Content = forwardRef<ExternalLayoutContentWidgetProps, 'div'>(
  ({ header, form, ctaSection, footer, ...props }, ref) => {
    const isMobile = useIsMobile();
    const isHideLegalInfo = ctaSection?.displayLegalInfo === false;
    const contentMobileHeight = useBreakpointValue({ xs: 'calc(100dvh - 56px)', s: 'calc(100dvh - 72px)' });

    return (
      <Card
        data-component="ExternalLayoutContentWidget"
        {...props}
        paddingX="none"
        paddingY="none"
        width="full"
        ref={ref}
      >
        <Group variant="vertical" spacing="none" width="full" style={isMobile ? { height: contentMobileHeight } : {}}>
          <Group variant="vertical" spacing="m" style={isMobile ? { overflow: 'auto', flexGrow: 1 } : {}}>
            <Group variant="vertical" spacing="none">
              {header && <ExternalLayoutContentWidget.Header {...header} />}
              {form && <ExternalLayoutContentWidget.Form {...form} />}
              {isMobile && !isHideLegalInfo && (
                <Container paddingX="l" paddingY="m">
                  <PCIComplianceInfo legalInfoOverride={ctaSection?.legalInfoOverride} />
                </Container>
              )}
              {footer && (
                <Container paddingX="l" paddingY="m">
                  {footer}
                </Container>
              )}
            </Group>
          </Group>
          <ExternalLayoutContentWidget.CTASection {...ctaSection} />
        </Group>
      </Card>
    );
  }
);

Content.displayName = 'ExternalLayoutContentWidget';

export const ExternalLayoutContentWidget = Object.assign(Content, components);

import { ThemeOptions } from '@melio/penny';

// off-the-shelf partners that we'll eventually move to store the config in db
export const payPalTheme: ThemeOptions = {
  logos: {
    dark: 'https://platform-static.meliopayments.com/assets/paypal/PayPalLogo.svg',
    light: 'https://platform-static.meliopayments.com/assets/paypal/PayPalLogo.svg',
  },
  borderRadii: {
    semantic: {
      action: {
        default: '9999px',
      },
    },
  },
  fonts: {
    primary: 'Paypal-Open',
  },
  colors: {
    semantic: {
      action: {
        criticalPrimary: {
          rest: '{global.brand.700}',
          hover: '{global.brand.700}',
          pressed: '{global.brand.700}',
          disabled: '{global.brand.700}',
        },
      },
    },
    global: {
      brand: {
        100: '#DDEEFF',
        200: '#AED7FF',
        300: '#73B9FF',
        400: '#5196F0',
        500: '#2279EC',
        600: '#1161CA',
        700: '#0551B5',
        800: '#003087',
        900: '#001D52',
        1000: '#000D24',
      },
      brandSecondary: {
        100: '#DDEEFF',
        200: '#AED7FF',
        300: '#73B9FF',
        400: '#5196F0',
        500: '#2279EC',
        600: '#1161CA',
        700: '#0551B5',
        800: '#003087',
        900: '#001D52',
        1000: '#000D24',
      },
      warning: {
        100: '#FFEEC2',
        200: '#FFECB2',
        300: '#FFE594',
        400: '#FFDF7B',
        500: '#FFD961',
        600: '#FFC614',
        700: '#FFBE4A',
        800: '#FFA300',
        900: '#DF930C',
        1000: '#A35441',
      },
      success: {
        100: '#E6F8E7',
        200: '#B1E2C4',
        300: '#9BDAB3',
        400: '#8FD01D',
        500: '#54BF7E',
        600: '#119C18',
        700: '#0C6E11',
        800: '#0A570D',
        900: '#063708',
        1000: '#042005',
      },
      informative: {
        100: '#DDEEFF',
        200: '#AED7FF',
        300: '#73B9FF',
        400: '#5196F0',
        500: '#2279EC',
        600: '#1161CA',
        700: '#0551B5',
        800: '#003087',
        900: '#00266B',
        1000: '#001842',
      },
      critical: {
        100: '#FFE8EC',
        200: '#FEDCDC',
        300: '#FEC3C3',
        400: '#FEA9A9',
        500: '#FE7575',
        600: '#FE3435',
        700: '#D50102',
        800: '#B20102',
        900: '#870101',
        1000: '#6D030D',
      },
      decorative: { 1: '#DDEEFF', 2: '#FEDCDC', 3: '#FFE594', 4: '#B1E2C4', 5: '#FFC614' },
    },
    component: {
      pill: {
        secondaryWarning: {
          rest: {
            label: '{global.warning.1000}',
          },
        },
        secondaryCritical: {
          rest: {
            label: '{global.critical.1000}',
          },
        },
      },
      tab: {
        default: {
          selected: {
            border: '{global.neutral.1000}',
            label: '{global.neutral.1000}',
          },
          pressed: {
            label: '{global.neutral.1000}',
          },
          hover: {
            label: '{global.neutral.1000}',
          },
        },
      },
    },
  },
  // @ts-ignore
  borders: {
    selection: {
      selectedBrand: '2px solid #003087',
    },
  },
};

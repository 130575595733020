import { useCollectionApi, UseCollectionApiProps, useNonEntityMutation } from '@melio/api-client';
import {
  FundingSourcesApiClient,
  FundingSourcesApiGetFundingSourcesRequest,
  ServicesApiClient,
} from '@melio/platform-api-axios-client';

import { ApiError } from '../../core/types';
import { FundingSource, PatchFundingSourcesRequest, PostFundingSourcesRequest } from './types';

type QueryFn = typeof FundingSourcesApiClient.getFundingSources;
type CreateFn = typeof FundingSourcesApiClient.postFundingSources;
type DeleteFn = typeof FundingSourcesApiClient.deleteFundingSourcesFundingSourceId;
type UpdateFn = typeof FundingSourcesApiClient.patchFundingSourcesFundingSourceId;

export type UseFundingSourcesProps = UseCollectionApiProps<QueryFn, FundingSource> & {
  params?: FundingSourcesApiGetFundingSourcesRequest;
  onCreate?: (data: FundingSource) => void;
  onVerifyError?: (err: ApiError) => void;
  onCreateError?: (err: ApiError) => void;
};

export const useFundingSources = ({ params, onVerifyError, ...props }: UseFundingSourcesProps = {}) => {
  const query = useCollectionApi<
    QueryFn,
    CreateFn,
    UpdateFn,
    DeleteFn,
    FundingSource,
    PostFundingSourcesRequest,
    PatchFundingSourcesRequest
  >({
    ...props,
    queryKey: ['FundingSourcesApi', params],
    queryFn: () => FundingSourcesApiClient.getFundingSources(params?.expand, params?.melioAccount, params?.include),
    createFn: FundingSourcesApiClient.postFundingSources,
    deleteFn: FundingSourcesApiClient.deleteFundingSourcesFundingSourceId,
    updateFn: FundingSourcesApiClient.patchFundingSourcesFundingSourceId,
  });

  const verifyCardMutation = useNonEntityMutation(ServicesApiClient.postServicesVerifyCard, query.queryKey, {
    onError: onVerifyError,
  });

  return {
    ...query,
    verifyCard: verifyCardMutation.mutateAsync,
  };
};

export type FundingSourceCollection = ReturnType<typeof useFundingSources>;

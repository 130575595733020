// eslint-disable-next-line no-restricted-imports
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line no-restricted-imports
import { ExchangeIdsRequestDirectionEnum, IDExchangeApi, IDExchangeApiClient } from '@melio/platform-api-axios-client';

export function useExchangeIds() {
  const queryClient = useQueryClient();

  const exchangeIds = useCallback(
    async (direction: ExchangeIdsRequestDirectionEnum, ids: string[]) => {
      if (ids.length === 0) return {};

      const cacheKey = [IDExchangeApi];
      const cachedMappings = queryClient.getQueryData<Record<string, string>>(cacheKey) || {};

      const uncachedIds = ids.filter((id) => !cachedMappings[id]);

      if (uncachedIds.length === 0) {
        return cachedMappings;
      }

      const response = await queryClient.fetchQuery({
        queryKey: [IDExchangeApi, uncachedIds],
        queryFn: async () => {
          const { data } = await IDExchangeApiClient.exchangeIds({ ids: uncachedIds, direction });

          return (data.mappings || []).reduce<Record<string, string>>((acc, { sourceId, targetId }) => {
            acc[sourceId] = targetId;
            return acc;
          }, {});
        },
      });

      const newMappings = { ...cachedMappings, ...response };
      queryClient.setQueryData(cacheKey, newMappings);

      return newMappings;
    },
    [queryClient],
  );

  return { exchangeIds };
}

import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

export type CollectBusinessDetailsFormModel = {
  email: string;
  businessName: string;
  routingNumber: string;
  accountNumber: string;
};

export const useCollectBusinessDetailsSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    email: string()
      .required(formatMessage('vex.activities.collectBusinessDetails.validation.email.required'))
      .email(formatMessage('vex.activities.collectBusinessDetails.validation.email.invalid')),
    businessName: string().required(
      formatMessage('vex.activities.collectBusinessDetails.validation.businessName.required')
    ),
    routingNumber: string()
      .required(formatMessage('vex.activities.collectBusinessDetails.validation.routingNumber.required'))
      .matches(/^\d{9}$/, formatMessage('vex.activities.collectBusinessDetails.validation.routingNumber.invalid')),
    accountNumber: string()
      .required(formatMessage('vex.activities.collectBusinessDetails.validation.accountNumber.required'))
      .matches(/^\d{1,22}$/, formatMessage('vex.activities.collectBusinessDetails.validation.accountNumber.invalid')),
  }) as SchemaOf<CollectBusinessDetailsFormModel>;
};

import { datadogLogs } from '@datadog/browser-logs';
import { init as initFullStory } from '@fullstory/browser';

type SeverityLevel = 'debug' | 'info' | 'log' | 'warn' | 'error' | 'fatal';

const severityLevels: Record<SeverityLevel, number> = { debug: 0, info: 1, log: 2, warn: 3, error: 4, fatal: 5 };
const clientToken = 'pub012d37ef8b7e4b5efb04e359f4abf56c';
const site = 'datadoghq.com';
export type LoggerConfig = {
  environment: 'development' | 'production' | string;
  fullStoryOrgId: string;
  release?: string;
  tracesSampleRate?: number;
  level?: SeverityLevel;
  consoleEnabled?: boolean;
  remoteEnabled?: boolean;
  service?: string;
};

const DEFAULT_LEVEL: SeverityLevel = 'log';
const DEFAULT_CONFIG: LoggerConfig = {
  environment: 'development',
  fullStoryOrgId: '',
  consoleEnabled: true,
  level: DEFAULT_LEVEL,
  remoteEnabled: false,
};

export class Logger {
  private static __config: LoggerConfig = DEFAULT_CONFIG;
  private static get config() {
    const { consoleEnabled = true, level = DEFAULT_LEVEL, remoteEnabled = false } = this.__config;
    return { ...this.__config, consoleEnabled, level, remoteEnabled };
  }

  static init(config: LoggerConfig) {
    Logger.__config = config;
    const { remoteEnabled: enabled = false, fullStoryOrgId: orgId } = config;

    if (!enabled) {
      return;
    }

    datadogLogs.init({
      clientToken,
      site,
      service: config.service,
      sessionSampleRate: 100,
      env: config.environment,
      version: config.release,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['info', 'error'],
    });

    if (orgId) {
      initFullStory({ orgId, recordOnlyThisIFrame: window.top !== window });
    }
  }

  static log = (message: string, level: SeverityLevel = DEFAULT_LEVEL) => {
    if (severityLevels[level] < severityLevels[Logger.config.level]) {
      return;
    }

    this.consoleLog(message as never, level);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static handleException = (message: any) => {
    this.consoleLog(message as never, 'fatal');
  };

  static setUser({ id, email, orgId }: { id?: string; email?: string; orgId?: number }) {
    if (this.config.remoteEnabled) {
      datadogLogs.setUser({
        id,
        email,
        orgId,
      });
    }
  }

  private static consoleLog = (message: string, level: SeverityLevel) => {
    const logLevel = level === 'fatal' ? 'error' : level;
    if (Logger.config.consoleEnabled) {
      // eslint-disable-next-line no-console
      console[logLevel](`%cLogger:${level}`, 'color: purple; font-weight: bold', message);
    }
    if (this.config.remoteEnabled) {
      datadogLogs.logger[logLevel](message);
    }
  };
}

import { useMelioIntl } from '@melio/platform-i18n';

import { BaseSuccessScreen } from '../../base-screens';

export const CollectBusinessDetailsSuccess = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <BaseSuccessScreen
      title={formatMessage('vex.screen.collectBusinessDetails.success.title')}
      description={formatMessage('vex.screen.collectBusinessDetails.success.description')}
      data-testid="collect-business-details-success-screen"
      data-component="CollectBusinessDetailsSuccess"
      displayTrustedPartners
    />
  );
};

CollectBusinessDetailsSuccess.displayName = 'CollectBusinessDetailsSuccess';

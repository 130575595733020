import { merge } from 'lodash';
import {
  BillsEmailInboxDashboardView,
  MelioConfig,
  PartialMelioConfig,
  PaymentSchedulingPreference,
  TrustedPartners,
  VendorDetailsFormType,
} from '@melio/platform-provider';

import { PartnerConfig } from '@/partnersConfig.types';
import { defaultLinks } from '../links';

declare global {
  interface Window {
    dynamicEnvConfig?: PartialMelioConfig;
    config: PartnerConfig;
  }
}
export const _defaultConfig: MelioConfig = {
  tabTitle: 'Pay Bills',
  analytics: { key: '', shouldPrintEvents: false, shouldTrackEvents: false, blockedEventDestinations: [] },
  featureFlagProvider: { clientId: '', shouldLoad: false },
  production: false,
  redirectWhenMissingToken: false,
  settings: {
    externalIdsPassedForNavigation: false,
    Brand: {
      color: {
        enabled: false,
      },
      logo: { enabled: false },
    },
    expiredSessionTime: 15,
    unmaskVendorAccountNumber: false,
    registerToMelioGetPaid: 'https://app.meliopayments.com/meliome/register',
    icons: {
      fundingSource: {
        bank: 'bank',
      },
      deliveryMethod: {
        virtualCard: 'mastercard',
      },
    },
    migrationUserTour: {
      isEnabled: false,
      shouldFinishOnSessionEnd: true,
      modalImage:
        'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migrated-user-modal.json',
      stepsAnimations: {
        step1:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step1.json',
        step2:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step2.json',
        step3:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step3.json',
        step4:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step4.json',
        step5:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step5.json',
        step6:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step6.json',
      },
      stepImages: {
        step1:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step1.svg',
        step2:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step2.svg',
        step3:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step3.svg',
        step4:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step4.svg',
        step5:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step5.svg',
        step6:
          'https://platform-static.meliopayments.com/assets/default/illustrations/migrationUserTour/migration_user_tour_step6_first_frame.png',
      },
    },
    assets: {},
    nickNameValidation: false,
    settings: {
      options: [],
    },
    collectMissingCompanyLegalInfoFields: true,
    billsEmailInboxDashboardView: BillsEmailInboxDashboardView.Link,
    supportRequestTimeoutEnable: false,
    showRetryButtonOnFieldValidation: true,
    payment: {
      newPaymentFlow: {
        enabled: false,
      },
      showBillForJustPayPayments: false,
      supportedFundingSourceTypes: {
        'bank-account': true,
        card: true,
        'flex-account': false,
        'paypal-balance': false,
      },
      isConfirmationNumberEnabled: false,
      loadingStates: {
        initial: ['dm'],
        vendor: [],
        amount: [],
        fs: ['dm'],
        dm: [],
        recurrenceType: [],
        date: [],
        bill: [],
      },
      scheduling: {
        scheduleBy: PaymentSchedulingPreference.ByScheduleDate,
        showEarlierApprovalDate: false,
        showFuturePaymentBanner: false,
        maxDeliveryDateInDays: 364,
        disableEditDeliveryDateForCards: false,
        recurringEndDateRestrictionYears: 5,
        minAmountUsd: 0,
        maxAmountUsd: Number.MAX_SAFE_INTEGER,
        fundingSourceTypeSelectionEnabled: true,
        removeIneligibleFundingSources: false,
        disableFundingSourceTypeToggleIfNoOptions: false,
        initialFormCollapsedState: 'partial',
        goodsReceivedModal: {
          enabled: true,
          amountThreshold: 3000,
        },
      },
      editing: {
        defaultToManagedAccountPreferredDeliveryMethod: false,
        shouldCheckFundingSourceEntitlements: false,
        disableEditDeliveryMethod: false,
        fundingSourceTypeSelectionEnabledOnEdit: true,
      },
      scheduled: {
        showZeroFees: true,
        showEstimatedDeliveryDateBanner: false,
      },
      showCreatedByIndication: false,
      memoToVendor: {
        maxLength: 50,
        memoRegex: { pattern: '.*' },
        deliveryMethodBlackList: ['biller-account', 'managed-account'],
        allowSendingEmailToVendorWhenAddingMemo: true,
      },
      noteToSelf: {
        noteRegex: { pattern: '.*' },
      },
      allowNotifyingVendor: true,
      flowOfFundsIndicator: {
        enabled: true,
        showDateRange: true,
      },
      firstTimeAchDeliveryMethodTourtip: {
        enabled: false,
      },
    },
    batchPayments: {
      enabled: true,
      showZeroFees: true,
      showDeliverySpeedColumn: true,
      showVendorVerifiedDeliveryMethodTooltip: true,
      usePreDefinedlDeliveryMethodList: false,
      showDebitSameDateIndication: false,
      showLateIndicator: true,
      showFeesTooltipTitle: true,
      showVendorDetails: false,
      showRelativeDates: false,
      columnActions: {
        deliveryDate: {
          disabled: [],
        },
      },
      showFeesBreakdown: false,
      fundingSourceSelectWithLabels: false,
      maxBillsToCombine: 20,
    },
    fundingSourcePolicy: {
      fundingSourceCard: {
        showCreatedBy: false,
      },
      'bank-account': {
        order: 0,
        fundingSourceGroup: 'bank-account',
        read: false,
        write: false,
      },
      credit: {
        order: 1,
        fundingSourceGroup: 'credit',
        read: false,
        write: false,
      },
      debit: {
        order: 2,
        fundingSourceGroup: 'debit',
        read: false,
        write: false,
      },
    },
    uploadBill: {
      shouldShowMultipleBillUploadIndication: true,
    },
    vendor: {
      collectedDetails: 'minimal',
      requiredDirectoryFields: [],
      importVendorFieldsList: [],
      vendorSelect: {
        createVendor: {
          showVendorDetailsForm: { enabled: false },
          vendorDetailsFormType: VendorDetailsFormType.Modal,
        },
      },
      createVendor: {
        showCompanyNameTooltip: false,
        hasSkipDeliveryMethodButton: true,
        shouldSwitchToExtendedFormOnApiErrors: false,
        shouldShowBankDetailsFields: false,
        shouldShowAddressDetailsBanner: false,
        shouldShowDeliveryMethodsSection: false,
        shouldCollectAccountNumberForUnmanagedVendor: false,
        uniqueNameFormatRegexp: { pattern: "^[a-zA-Z0-9 ,.?@&!#'~*()_+;-]+$" },
        shouldEnforceAddingDeliveryMethod: false,
      },
      forms: {
        shouldUseZipCodeMask: false,
        shouldUseSearchVendorSectionLabel: true,
        shouldUseTooltipsForManagedVendorForm: true,
        shouldRequireEmail: false,
        shouldDisableEmailEdit: false,
        shouldCollectCountry: false,
      },
      warnEditingVendorWithFuturePayments: false,
    },
    paymentLateApproval: {
      debitDatePassedIndicationEnabled: true,
      debitDateTodayIndicationEnabled: false,
    },
    isMultiColoredAvatarsEnabled: true,
    isPaymentApprovalLimitEnabled: false,
    showApproveActionButton: true,
    exclusiveUserViewEnabled: { enabled: false },
    isDeclinePaymentReasonEnabled: true,
    isVendorLimitedToSingleVendorGroup: {
      enabled: false,
    },
    collaborator: {
      isAddCollaboratorEnabled: true,
      isChangeCollaboratorPermissionLevelEnabled: false,
      showRoleTransferDisclaimer: false,
      rolesOrderMapping: {},
    },
    isEntitlementsEnabled: false,
    newBillExperience: {
      isRecurringPaymentImprovementsEnabled: true,
      shouldShowIconInBillsTabCta: false,
    },
    payDashboardBillsTabTourEnabled: false,
    payDashboardSearchLabelDisabled: true,
    arDashboardSearchLabelDisabled: true,
    newPayExperience: {
      firstTimeExperience: {
        assetType: 'image',
        imageUrl: 'https://platform-static.meliopayments.com/assets/default/illustrations/first-time-experience.png',
        mobileImageUrl:
          'https://platform-static.meliopayments.com/assets/default/illustrations/first-time-experience.png',
        videoLink: '',
      },
    },
    accountingFirmDashboard: {
      emptyStateAsset:
        'https://platform-static.meliopayments.com/assets/default/illustrations/accountants-dashboard-illustration.svg',
    },
    pciCompliance: {
      link: 'https://www.meliopayments.com/security',
    },
    isUnilateralDeliveryMethodSupported: true,
    isPaymentTimelineEnabled: false,
    zenworkPricingPolicies: 'https://www.tax1099.com/tax-1099-efile-pricing',
    contractorsInvalidTINLink:
      'https://help.melio.com/hc/en-us/articles/17355750780956-Requesting-W-9-Forms-for-Vendors-whose-TIN-didn-t-match',
    contractorsPendingW9Link:
      'https://help.melio.com/hc/en-us/articles/17357232891548-E-filing-1099-Handling-Pending-W-9-Forms',
    contractorLearnMoreLink:
      'https://melio.zendesk.com/hc/en-us/articles/4418745975324-Can-I-use-Melio-to-pay-a-1099-Contractor?utm_campaign=w9&utm_medium=inproduct&utm_source=',
    tax1099LearnMoreLink:
      'https://melio.zendesk.com/hc/en-us/articles/15966635925020?utm_source=&utm_medium=inproduct&utm_campaign=w9',
    virtualCardLearnMoreLink:
      'https://melio.zendesk.com/hc/en-us/articles/4416039832732-Why-has-my-vendor-s-delivery-method-changed-to-single-use-virtual-card',
    showDemoRequestIcon: true,
    contextualOnboarding: {
      buttons: [],
    },
    termAndConditionsLink: 'https://meliopayments.com/terms-of-service',
    privacyPolicyUrl:
      'https://www.meliopayments.com/legal/privacy-policy?_ga=2.9364146.662432983.1653317311-444475439.1647269629',
    requireInvoiceForBillsAmountThreshold: 10000,
    helpCenterUrl: 'https://melio.zendesk.com/hc/en-us',
    howContactToSupportArticleUrl:
      'https://melio.zendesk.com/hc/en-us/articles/4951819705628-All-the-ways-to-contact-Melio-customer-support',
    fileSizeLimit: 10 * 1024 * 1024, // 10MB
    fileAllowedFormats: ['pdf', 'png', 'jpg'],
    tokenProvider: 'tbt',
    eoyCheck: {
      enabled: false,
    },
    unilateralRequest: {
      announceFees: false,
    },
    trustedPartners: [TrustedPartners.Mastercard, TrustedPartners.Quickbooks, TrustedPartners.Visa],
    mccLearnMoreUrl:
      'https://melio.zendesk.com/hc/en-us/articles/4415821314076-A-change-in-how-you-pay-using-your-American-Express-card',
    supportEmail: 'webmaster@example.com',
    verificationEmail: 'verification@example.com',
    complianceEmail: 'compliance@example.com',
    termsOfServiceUrl:
      'https://www.meliopayments.com/legal/terms-of-service?_ga=2.47262112.662432983.1653317311-444475439.1647269629',
    preSeason1099BannerLearnMoreLink:
      'https://melio.zendesk.com/hc/en-us/articles/15907255820700-How-to-request-your-vendor-for-a-W-9-form-in-Melio',
    preSeason1099BannerPendingW9FormsLearnMoreLink:
      'https://melio.zendesk.com/hc/en-us/articles/15907255820700-How-to-request-your-vendor-for-a-W-9-form-in-Melio#h_01J8F992EC7QPR7G1HVMW7EKQD',
    learnMoreAccountVerificationURL: 'https://melio.zendesk.com/hc/en-us/articles/7104668877084',
    partnerDisplayName: 'Melio',
    partnerProductName: 'Melio',
    virtualCardExpiryPeriodInDays: 0,
    deliveryMethodTypeOrder: {
      'bank-account': 1,
      'domestic-wire-account': 2,
      'paper-check': 3,
      'virtual-card': 4,
      'virtual-account': 5,
      'international-account': 6,
      'paypal-balance': 7,
    },
    deliveryMethodTypeOptionDisabledReasons: {
      'bank-account': ['vendorIsBank'],
      'paper-check': [],
      'domestic-wire-account': [],
      'virtual-account': ['vendorIsBank'],
      'international-account': ['cardFundingSourceNotSupported', 'vendorIsBank', 'deliveryMethodNotSupportedForState'], // TODO: maybe merge with fundingSourceNotSupported
      'virtual-card': [],
    },
    syncProgressIndicationEnabled: false,
    supportPhoneNumber: '',
    vex: {
      zdSupportFormUrl: 'https://melio.zendesk.com/hc/en-us/requests/new',
      vendorsContactEmail: 'ar-vendors-support@melio.com',
      persistentFastOptOutArticleUrl:
        'https://melio.zendesk.com/hc/en-us/articles/12413481862812?utm_source=&utm_medium=&utm_campaign=opt-out-fast-preference',
      unilateral: {
        isEmailLoginEnabled: false,
        shouldSuggestJoinMelio: false,
        isNewUnilateral: false,
        suvcInformationLink: 'https://meliopayments.com/guides/single-use-virtual-cards/',
      },
    },
    showCheckMarkIconForManagedVendor: true,
    singlePaymentStepLayout: {
      backgroundColor: 'lightest',
    },
    accountingSoftware: {
      isConnectConfirmationEnabled: false,
    },
    guestPaymentFlow: {
      shouldGenerateDeepLinkToPaymentRequestPayDashboard: false,
      goodsReceivedAmountThreshold: 3000,
    },
    File1099SettingsTaxAndReports: {
      flagName: 'platform-feature-1099-tax-and-reports-settings',
      enabled: false,
    },
    ShowOnlyFastestDeliveryPreference: {
      enabled: false,
      flagName: 'platform-show-only-fastest-preference',
    },
    PlatformApprovalWorkflowsV2: {
      flagName: 'platform-approval-workflows-v2',
      enabled: false,
    },
    File1099PreSeasonBanner: {
      flagName: '1099-pre-season-banners-dashboard',
      enabled: false,
    },
    File1099SeasonBanner: {
      flagName: '1099-season-banner-dashboard',
      enabled: false,
    },
    EbillSubscriptionCancelPaper: {
      flagName: 'ebill-subscription-cancel-paper',
      enabled: false,
    },
    ScannedInvoiceDisclaimer: {
      enabled: false,
    },
    ZenworkW9Form: {
      flagName: 'platform-feature-zenwork-w-9-form',
      enabled: true,
    },
    Import1099Contractors: {
      flagName: '1099-import-contractors',
      enabled: false,
    },
    ApprovalWorkflowPendingApprovers: {
      flagName: 'platform-approval-workflow-pending-approvers',
      enabled: true,
    },
    BillingFeeSettingsForExternalAccountants: {
      flagName: 'enable-billing-fee-settings-for-external-accountant',
      enabled: false,
    },
    BlockRPPSPaymentsForOrganizations: {
      flagName: 'block-rpps-payments-for-organizations',
      enabled: true,
    },
    NewPaymentFlowAddCardDrawer: {
      enabled: false,
    },
    PaypalBalanceDeliveryMethodEnabled: {
      enabled: false,
    },
    BlockInternationalPaymentsForOrganizations: {
      flagName: 'block-international-payments-for-organizations',
      enabled: true,
    },
    PaymentNoteToSelf: {
      enabled: false,
    },
    ShowTotalPaymentIncludingFees: {
      enabled: false,
    },
    OnboardingModalEnabled: {
      flagName: 'platform-feature-onboarding-modal-enabled',
      enabled: true,
    },
    MigratedUserTourTripEnabled: {
      flagName: 'new-tour-tip-for-migrated-users',
      enabled: false,
    },
    BillingStatementsTab: {
      flagName: 'billing-statements-tab',
      enabled: false,
    },
    promoteAchDeliveryMethod: false,
    newConsentScreen: true,
    verifyFundingSourceOnlyByCreator: {
      enabled: false,
    },
    hideBusinessTypeInCompanyInfo: {
      enabled: false,
    },
    showInsufficientCreditBanner: {
      flagName: 'platform-show-insufficient-credit-card-banner',
      enabled: false,
    },
    AllowCaratIntegration: {
      enabled: false,
    },
    showNoCreditCardBanner: {
      flagName: 'platform-show-no-credit-card-banner',
      enabled: false,
    },
    CollectVendorAddress: {
      flagName: 'platform-collect-vendor-address',
      enabled: false,
    },
    InternationalFx: {
      enabled: false,
    },
    EnableInternationalUSDWise: {
      enabled: false,
      flagName: 'enable-international-usd-wise',
    },
    defaultTZ: {
      enabled: false,
    },
    PaymentsTabStatusCellRefundDescription: {
      enabled: false,
    },
    MarkAsPaid: {
      flagName: undefined,
      enabled: undefined,
    },
    EBillLegalDisclaimer: {
      flagName: undefined,
      enabled: undefined,
    },
    VendorGroups: {
      flagName: undefined,
      enabled: undefined,
    },
    UploadVendorsCsv: {
      flagName: undefined,
      enabled: undefined,
    },
    PreviewInvoice: {
      flagName: undefined,
      enabled: undefined,
    },
    PayDashboardSaveSort: {
      flagName: undefined,
      enabled: undefined,
    },
    PaymentsTabFilters: {
      flagName: 'platform-payments-filters',
      enabled: false,
    },
    PaymentsTabFiltersPhase2: {
      flagName: 'platform-payments-filters-phase-2',
      enabled: false,
      supportedStatuses: {
        scheduled: true,
        inProgress: true,
        paid: true,
        failed: true,
        markedAsPaid: true,
        pending: true,
        refunded: true,
        canceled: false,
        declined: false,
      },
    },
    PushFxForInternationalOrgs: {
      flagName: 'push-fx-for-international-orgs',
      enabled: false,
    },
    BillsTabFiltersInfra: {
      flagName: 'platform-bills-filters',
      enabled: false,
    },
    BillsTabFiltersMenu: {
      flagName: 'platform-bills-filters-phase-2',
      enabled: false,
    },
    VendorGroupsSaveFilter: {
      enabled: false,
    },
    PayDashboardTimelineDisableDates: {
      flagName: undefined,
      enabled: undefined,
    },
    InvalidVendorBankAccountModal: {
      flagName: undefined,
      enabled: undefined,
    },
    Todos: {
      icon: 'notification',
    },
    PayDashboardSearchOnKeyPress: {
      flagName: undefined,
      enabled: undefined,
    },
    ShowLineItemsPremiumPill: {
      flagName: 'platform-feature-show-line-items-premium-pill',
      enabled: true,
    },
    OutdatedSessionModal: {
      flagName: undefined,
      enabled: false,
    },
    MtlKycUpliftCompanyDetails: {
      flagName: undefined,
      enabled: false,
    },
    PlatformMultipleVendorsWithTheSameName: {
      flagName: undefined,
      enabled: false,
    },
    ShowVendorDetailsModalForVisa: {
      enabled: true,
    },
    ShowVendorDetailsModalForAmex: {
      enabled: true,
    },
    BatchPaymentsSubmitErrors: {
      flagName: 'platform-batch-payment-submit-errors',
      enabled: false,
    },
    PromoteAccountingSoftwareSync: {
      enabled: false,
    },
    PromoteCardFundingSource: {
      enabled: false,
    },
    FofImprovements: {
      enabled: false,
    },
    DeliveryMethodPublishNewEvents: {
      flagName: 'publish-delivery-method-new-events',
      enabled: false,
    },
    AccountantsPeriodicSummaryEmail: {
      flagName: 'accountants-digest-email-feature',
      enabled: false,
    },
    NewBusinessesSearch: {
      flagName: 'platform-new-businesses-search',
      enabled: false,
    },
    IsOrgOwesUsMoneyBannerEnabled: {
      flagName: 'is-org-owes-us-money-banner-enabled',
      enabled: false,
    },
    IsEddBannerEnabled: {
      flagName: 'is-edd-banner-enabled',
      enabled: false,
    },
    DisplayCancelPaymentsBannerInCancelFlowForClients: {
      flagName: 'display-cancel-payments-banner-in-cancel-flow-for-clients',
      enabled: false,
    },
    MultiAccountBanner: {
      enabled: false,
    },
    VendorDeliveryMethodCombinedExperience: {
      enabled: false,
    },
    IsArFeeAbsorptionSettingsEnabled: {
      flagName: 'is-ar-fee-absorption-settings-enabled',
      enabled: false,
    },
    DuplicateVendors: {
      flagName: 'sync-deactivated-for-register',
      enabled: false,
    },
    PlatformExpenseAccountControl: {
      flagName: 'platform-expense-account-control',
      enabled: true,
    },
    CheckAllVendorsWhenMatching: {
      flagName: 'check-all-vendors-when-matching',
      enabled: false,
    },
    AutoMatchHighConfidenceVendors: {
      flagName: 'auto-match-high-confidence-vendors',
      enabled: false,
    },
    MidConfidence: {
      flagName: 'mid-confidence',
      enabled: false,
    },
    PdfViewerFixes: {
      flagName: 'pdf-viewer-fixes',
      enabled: true,
    },
    TermsAndConditions: {
      flagName: 'sign-terms-and-conditions',
      enabled: false,
    },
    RedirectOnTermsAndConditionsScreenReject: {
      enabled: false,
    },
    isBillsInboxEnabled: true,
    isUploadBillsEnabled: true,
    isContactSupportEnabled: true,
    invoices: {
      allowUploadAttachments: true,
    },
    ARPaymentsTab: {
      enabled: false,
    },
    shouldDownloadQbdtConnectArticle: false,
    InternationalWire: {
      flagName: 'international-wire',
      enabled: false,
    },
    VendorCredits: {
      flagName: 'vendor-credits',
      enabled: false,
    },
  },
  services: {
    googleMaps: {
      apiKey: 'AIzaSyCy2BbnUakgGy08r_gmL8Qlo0VikreL7WA',
    },
    userSnap: {
      spaceId: '39ab2947-23d9-4768-a96f-9eff5f508557',
      projectId: '786dcee3-f5f6-42da-93f7-15db525c1e1b',
    },
    tbt: {
      apiKey: 'key_GZFc6KNn6zbN6Y54aFZfpV',
      revealVirtualCardApiKey: 'key_test_us_pub_C2kZjn4VB4beh6nW2ZSQAa',
      supportedCardProviders: {
        visa: true,
        mastercard: true,
        'american-express': true,
        'diners-club': true,
        discover: true,
        unknown: false,
      },
      fontsUrl: 'https://platform-static.meliopayments.com/fonts/fonts-config.css',
    },
    carat: {
      supportedCardProviders: {
        visa: false,
        mastercard: false,
        'american-express': false,
        'diners-club': false,
        discover: false,
        unknown: false,
      },
    },
    zendeskKey: '6cf823a4-5854-401a-8e1f-c92a3b5d0f49', // Sandbox V.2 -> Melio - Sandbox Widget
    zendeskWorkflow: 'Melio Routing',
    googleClientId: '619633578816-tb15fe2uqjetng2fhfq7kbeo83e69kpp.apps.googleusercontent.com',
    tabapay: {
      card: {
        url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
      },
    },
    smartystreets: {
      apiKey: '27409250903411363',
    },
    plaid: {
      env: 'sandbox',
      apiKey: '7f066139be81ee696f3a4c27a78d7e',
    },
  },
  server: {
    apiServer: process.env.API_SERVER || '',
  },
  tutorialVideo: 'https://www.youtube.com/embed/eqg9fpX3tMk',
  dataDog: {
    shouldInit: false,
    appId: '1cae8098-c814-4441-84f4-d83a35abb272',
    clientId: 'pub012d37ef8b7e4b5efb04e359f4abf56c',
    site: 'datadoghq.com',
    service: 'platform-app',
  },
  zendesk: {
    headerColor: '#BFBFBF',
    resultListsColor: '#BFBFBF',
    themeColor: '#BFBFBF',
    title: 'Payment Support',
    logo: '',
  },
  links: defaultLinks,
  feedback: {
    triggers: {
      payment_success: {
        enabled: false,
        userSnapProjectId: '',
      },
      first_time_invoice_creation: {
        enabled: false,
        userSnapProjectId: '',
      },
      payment_received_marked_as_paid: {
        enabled: false,
        userSnapProjectId: '',
      },
    },
  },
};

export const defaultConfig = merge(
  _defaultConfig,
  typeof window !== 'undefined'
    ? {
        ...window.dynamicEnvConfig,
        environment: window.config?.environmentName,
      }
    : {},
);

import { CTASection } from './CTASection';
import { Form } from './Form';
import { Header } from './Header';

export type { ExternalLayoutContentWidgetCTASectionProps } from './CTASection';
export type { ExternalLayoutContentWidgetFormProps } from './Form';

export const components = {
  CTASection,
  Header,
  Form,
};

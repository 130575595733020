import { FC, PropsWithChildren } from 'react';

import {
  AsyncLocalizationProvider as _AsyncLocalizationProvider,
  AsyncLocalizationProviderProps,
} from './AsyncLocalizationProvider';
import { FormattedMessage as _FormattedMessage, FormattedMessageProps } from './FormattedMessage';
import { LocalizationProvider as _LocalizationProvider, LocalizationProviderProps } from './LocalizationProvider';
import { BaseTranslations, Renderers } from './types';
import { useMelioIntl as _useMelioIntl } from './useMelioIntl';
import { renderers as _renderers } from './utils';

export function createProvider<T extends BaseTranslations>(messages: T, renderers: Renderers = {}) {
  const useMelioIntl = () => _useMelioIntl<T>({ ..._renderers, ...renderers });

  const LocalizationProvider: FC<PropsWithChildren<Omit<LocalizationProviderProps<T>, 'messages'>>> = (props) => (
    <_LocalizationProvider {...props} messages={messages} />
  );
  const AsyncLocalizationProvider: FC<PropsWithChildren<Omit<AsyncLocalizationProviderProps<T>, 'messages'>>> = (
    props
  ) => <_AsyncLocalizationProvider {...props} messages={messages} />;

  const FormattedMessage: FC<PropsWithChildren<Omit<FormattedMessageProps<T>, 'formatMessage'>>> = (props) => (
    <_FormattedMessage<T> {...props} formatMessage={useMelioIntl().formatMessage} />
  );

  return {
    LocalizationProvider,
    useMelioIntl,
    FormattedMessage,
    AsyncLocalizationProvider,
  };
}

import { LocalStorageKeys } from '@melio/local-storage';

export type RedirectTokenContent = {
  path: string;
  query?: string;
};

export function getReturnUrl() {
  const currentUrl = new URL(window.location.href);
  const sessionBasePath = window.sessionStorage.getItem(LocalStorageKeys.basePath);
  const basePath = sessionBasePath ? `/${sessionBasePath}` : '';

  let pathname = currentUrl.pathname;
  if (pathname.startsWith(basePath)) {
    pathname = pathname.slice(basePath.length);
  }

  return encodeRedirectToken({
    path: pathname,
    query: currentUrl.search,
  });
}

export function encodeRedirectToken(json: RedirectTokenContent) {
  return btoa(JSON.stringify(json)).replace(/\+/g, '-').replace(/\//g, '_');
}

export function decodeRedirectToken(token: string) {
  try {
    const decodedText = atob(token.replace(/-/g, '+').replace(/_/g, '/'));
    const res = JSON.parse(decodedText) as RedirectTokenContent;

    let query = '';

    if (res.query) {
      if (res.query.startsWith('?')) {
        query = res.query;
      } else {
        query = '?' + res.query;
      }
    }

    return {
      redirectUrl: `${res.path}${query}`,
    };
  } catch (e) {
    return {
      redirectUrl: null,
    };
  }
}

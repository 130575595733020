import { useSendMessage } from '@melio/partner-bridge';
import { useEffect } from 'react';

import {
  PlatformAppSDKOutgoingMessagePayload as OutgoingPayloads,
  PlatformAppSDKOutgoingMessageTypes as OutgoingTypes,
} from '../partner-messages';

export function useFrameSizeChangeHandler() {
  const sendMessage = useSendMessage();
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const [entry] = entries;
      if (entry) {
        sendMessage(OutgoingTypes.CONTENT_SIZE_CHANGE, {
          height: entry.target.clientHeight,
          width: entry.target.clientWidth,
        } as OutgoingPayloads['CONTENT_SIZE_CHANGE']);
      }
    });
    resizeObserver.observe(document.body);
    return () => resizeObserver.disconnect();
  }, [sendMessage]);
}

import { partnerBridgeService } from '@melio/partner-bridge/src/PartnerBridgeService';
import { getReturnUrl } from '@melio/partner-navigation-manager';

import { OutgoingPostMessagePayload, OutgoingPostMessageTypes } from '@/utils/partnerBridge.utils.types';

type ErrorData = {
  errorCode: string;
  requiredAction?: string;
  rawData?: unknown;
};

export function handleError(error: unknown, onErrorCallback?: (e?: unknown) => void) {
  const typedError = error as { message: string; data: ErrorData[] };
  const errorData = typedError.data?.[0];

  if (errorData?.requiredAction) {
    partnerBridgeService.sendMessage(OutgoingPostMessageTypes.PARTNER_ACTION_REQUIRED, {
      data: {
        requiredAction: errorData.requiredAction,
        rawData: errorData.rawData,
        returnUrl: getReturnUrl(),
      },
    } as OutgoingPostMessagePayload['PARTNER_ACTION_REQUIRED']);

    return;
  }

  if (onErrorCallback) onErrorCallback(error);
}

import { handleTimeoutRequest } from '@melio/javascript-sdk/src/utils/handleTimeoutReqeust';
import { ApiDeleteRequest } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@tanstack/react-query';

import { AnyType, ApiError, ApiQueryKeyV2, MutationCallbackOptions, MutationContext } from './types';
import { useMelioQueryClientV2 } from './useMelioQueryClient';

type Options<TData = AnyType, TVariables = AnyType, TError = ApiError> = {
  onSuccess?: MutationCallbackOptions<TData, TVariables, unknown, TError>['onSuccess'];
  onError?: MutationCallbackOptions<void, unknown, unknown, TError>['onError'];
};

export function useDeleteMutationV2<TQueryFn extends ApiDeleteRequest = never, TError = ApiError>(
  mutationFn: TQueryFn | undefined,
  queryKey: ApiQueryKeyV2,
  options: Options<AnyType, string, TError> = {}
) {
  const queryClient = useMelioQueryClientV2();
  const [entity] = queryKey;

  const fn = mutationFn ? (id: string) => mutationFn(id)?.then(() => undefined) : undefined;

  const mutation = useMutation<AnyType, TError, string, MutationContext<AnyType>>(queryKey, fn, {
    onSuccess: (...args) => {
      void queryClient.invalidateQueries(entity, 'collection');
      void queryClient.invalidateQueries(entity, 'paginated-collection');
      // set model to undefined to make it unavailable in cache and not trigger refetch
      queryClient.setQueriesData([entity, 'model', args[1]], undefined);
      void options.onSuccess?.(...args);
    },
    onError: (error, variable, context) => {
      handleTimeoutRequest((error as ApiError)?.rawError || error);

      return options.onError?.(error, variable, context);
    },
  });

  const createCallback = (id?: string) => () => mutation.mutateAsync(id ?? '');

  return {
    ...mutation,
    createCallback,
  };
}

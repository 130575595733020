import { Container, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { ExternalLayoutWithCTA } from '@melio/vex-activities';
import { usePageView } from '@melio/vex-domain';
import {
  FooterWidget,
  PushToDebitHeaderWidget,
  PushToDebitHeaderWidgetProps,
  TBTFormWidget,
  TBTFormWidgetFields,
  TBTFormWidgetProps,
} from '@melio/vex-widgets';

import { ANALYTICS_CONTEXT } from '../../consts';

export type PaymentUpgradeTBTCardDetailsScreenProps = Omit<
  PushToDebitHeaderWidgetProps,
  'calculatedFee' | 'feePercentValue'
> & {
  calculatedFee: number;
  feePercentValue: number;
  handleDone: TBTFormWidgetProps['onSubmit'];
  handleBack?: VoidFunction;
  isSaving: boolean;
};

export const PaymentUpgradeTBTCardDetailsScreen = forwardRef<PaymentUpgradeTBTCardDetailsScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      feePercentValue,
      calculatedFee,
      handleBack,
      handleDone,
      isSaving,
      children,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { formatMessage, formatPercents, formatCurrency } = useMelioIntl();
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useBoolean(false);

    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<TBTFormWidgetFields>();

    const analyticsProps = {
      Intent: 'add-fast-delivery-method-details',
      DeliveryMethodChosen: 'card',
      PageName: 'add-your-debit-card-details-to-get-paid-instantly',
    };

    usePageView(ANALYTICS_CONTEXT, analyticsProps);

    const handleSubmit = async (data: TBTFormWidgetFields) => {
      track(ANALYTICS_CONTEXT, 'Click', { ...analyticsProps, Cta: 'link-my-card' });
      await handleDone(data);
    };

    return (
      <ExternalLayoutWithCTA
        data-component="PaymentUpgradeActivity.PaymentUpgradeTBTCardDetailsScreen"
        data-testid="vex-payment-upgrade-card-details-screen"
        ref={ref}
        {...props}
        header={{
          backButtonProps: handleBack ? { onClick: handleBack } : undefined,
          title: (
            <PushToDebitHeaderWidget
              accountName={accountName}
              paymentAmount={paymentAmount}
              paymentInvoiceNumber={paymentInvoiceNumber}
              paymentNote={paymentNote}
              showAvatar={!handleBack}
            />
          ),
        }}
        form={{
          title: formatMessage('activities.paymentUpgrade.screens.createCardDetails.title'),
          description: formatMessage('activities.paymentUpgrade.screens.cardDetails.description', {
            feePercent: formatPercents(feePercentValue, { divide: true }),
            calculatedFee: formatCurrency(calculatedFee),
          }),
          fields: (
            <Container paddingTop="m">
              <TBTFormWidget
                onSubmit={handleSubmit}
                onSubmissionStateChange={onSubmissionStateChange}
                onRenderError={setSubmitButtonDisabled.on}
                isSaving={isSaving}
                size="small"
              />
            </Container>
          ),
        }}
        ctaSection={{
          buttonProps: {
            ...submitButtonProps,
            isDisabled: isSubmitButtonDisabled || submitButtonProps?.isDisabled,
            label: formatMessage(`activities.paymentUpgrade.screens.createCardDetails.continue`),
          },
        }}
        footer={<FooterWidget />}
      />
    );
  }
);

PaymentUpgradeTBTCardDetailsScreen.displayName = 'PaymentUpgradeActivity.PaymentUpgradeTBTCardDetailsScreen';

export const PlatformAppSDKOutgoingMessageTypes = {
  CONTENT_SIZE_CHANGE: 'CONTENT_SIZE_CHANGE',
  SCROLL_TO: 'SCROLL_TO',
} as const;

export type PlatformAppSDKOutgoingMessagePayload = {
  [PlatformAppSDKOutgoingMessageTypes.CONTENT_SIZE_CHANGE]: {
    height: number;
    width?: number;
    scrollX?: number;
    scrollY?: number;
    preventScroll?: boolean;
  };
  [PlatformAppSDKOutgoingMessageTypes.SCROLL_TO]: {
    relativeScrollX?: number;
    relativeScrollY?: number;
    preventScroll?: boolean;
  };
};

export const PlatformAppSDKIncomingMessageTypes = {
  DIMENSIONS_CHANGED: 'DIMENSIONS_CHANGED',
  USER_SCROLL: 'USER_SCROLL',
} as const;

export type PlatformAppSDKIncomingMessagePayload = {
  [PlatformAppSDKIncomingMessageTypes.DIMENSIONS_CHANGED]: {
    windowHeight: number;
    windowWidth: number;
    elementDistanceFromTop: number;
    elementDistanceFromLeft: number;
  };
  [PlatformAppSDKIncomingMessageTypes.USER_SCROLL]: { scrollX: number; scrollY: number };
};

import { useEffect, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { ExchangeIdsRequestDirectionEnum } from '@melio/platform-api-axios-client';
import { useConfig } from '@melio/platform-provider';

import { useExchangeIds } from '@/hooks/useExchangeIds';
import { TARGET_ACTION_MAPPINGS } from './target.utils';

export const removePrefix = (s: string, prefix: string) => {
  if (prefix && s.startsWith(`${prefix}`)) {
    return s.slice(prefix.length);
  }

  return s;
};

export const useNavigationTarget = () => {
  const location = useLocation();
  const { exchangeIds } = useExchangeIds();
  const {
    settings: { externalIdsPassedForNavigation },
  } = useConfig();

  const [target, setTarget] = useState<string | null>(null);

  useEffect(() => {
    const fetchTarget = async () => {
      const matchedRoute = matchRoutes(
        TARGET_ACTION_MAPPINGS.map(({ path }) => ({ path })),
        location.pathname,
      );

      if (!matchedRoute || matchedRoute.length === 0) {
        setTarget(null);
        return;
      }

      const match = matchedRoute[0];
      const params = match.params || {};

      const mapping = TARGET_ACTION_MAPPINGS.find((m) => m.path === match.route.path);
      const extractedParams = { ...params };

      if (location.search && !mapping?.shouldIncludeQueryParam) {
        setTarget(null);
        return;
      }

      const searchParams = new URLSearchParams(location.search);

      if (mapping?.idInQueryParam) {
        const extractedId = searchParams.get(mapping.pathQueryParamKey);
        if (!extractedId) {
          setTarget(null);
          return;
        }
        extractedParams.id = extractedId;
      }
      if (externalIdsPassedForNavigation) {
        const idsToExchange = [];

        if (extractedParams.id) {
          idsToExchange.push(extractedParams.id);
        }

        if (extractedParams.ids) {
          idsToExchange.push(...extractedParams.ids.split(','));
        }

        if (idsToExchange.length > 0) {
          try {
            const entityType = mapping?.entityType ? `${mapping.entityType}_` : undefined;
            const response = await exchangeIds(ExchangeIdsRequestDirectionEnum.InternalToExternal, idsToExchange);

            if (extractedParams.id) {
              extractedParams.id = removePrefix(response[extractedParams.id], entityType || '');
            }
            if (extractedParams.ids) {
              extractedParams.ids = idsToExchange
                .map((id) => removePrefix(response[id] || id, entityType || ''))
                .join(',');
            }
          } catch (error) {
            /* empty */
          }
        }
      }

      const finalTarget = mapping.constructTarget(extractedParams);
      if (finalTarget !== target) {
        setTarget(finalTarget);
      }
    };

    fetchTarget();
  }, [target, location, exchangeIds, externalIdsPassedForNavigation]);

  return { target };
};

import { FundingSourceType } from '@melio/platform-api';

export const APActivitiesOutgoingMessageTypes = {
  ADD_FUNDING_SOURCE_CLICK: 'ADD_FUNDING_SOURCE_CLICK',
} as const;

export type APActivitiesOutgoingMessagePayload = {
  [APActivitiesOutgoingMessageTypes.ADD_FUNDING_SOURCE_CLICK]: {
    data: {
      fundingSourceType: FundingSourceType;
      returnUrl: string;
    };
  };
};

import { createMongoAbility, subject as buildSubject } from '@casl/ability';
import { PackRule, unpackRules } from '@casl/ability/extra';
import { useMelioQueryClient } from '@melio/platform-api';
import * as React from 'react';

import { usePermissionsProviderData } from './hooks/usePermissionsProviderData';
import type { CanFunction, ContextValue, MongoFinalAbility, Rule } from './types';

const PermissionsContext = React.createContext<ContextValue>({} as ContextValue);

type Props = {
  enabled?: boolean;
  accessToken?: string;
  children: React.ReactNode;
};

export const PermissionsProvider = ({ enabled: permissionsEnabled, accessToken, children }: Props) => {
  const { permissions, role, roles, isLoading, error } = usePermissionsProviderData({
    enabled: permissionsEnabled && !!accessToken,
  });
  const queryClient = useMelioQueryClient();

  const can = React.useCallback<CanFunction>(
    ({ action, subject, subjectData }) => {
      const unpacked: Rule[] = permissions ? unpackRules(permissions as PackRule<Rule>[]) : [];
      const ability = createMongoAbility<MongoFinalAbility>(unpacked);

      const finalSubject = subjectData ? buildSubject(subject, { ...subjectData }) : subject;

      return ability.can(action, finalSubject);
    },
    [permissions]
  );

  React.useEffect(() => {
    void queryClient.invalidateQueries('permissions');
    void queryClient.invalidateQueries('collaborator');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const value = { can, roles: roles ?? [], actorRole: role ?? null, isLoading, error };

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => {
  const context = React.useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(`${usePermissions.name} must be used within a ${PermissionsProvider.name}`);
  }
  return context;
};

import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { FormWidgetProps } from '@melio/vex-widgets';

import { CollectBusinessDetailsFormModel, useCollectBusinessDetailsSchema } from './useCollectBusinessDetailsSchema';

export type CollectBusinessDetailsFormProps = FormWidgetProps<CollectBusinessDetailsFormModel>;

export const CollectBusienssDetailsForm = forwardRef<CollectBusinessDetailsFormProps, 'form'>(
  ({ onSubmit, isSaving, onSubmissionStateChange, defaultValues }, ref) => {
    const { formatMessage } = useMelioIntl();
    const schema = useCollectBusinessDetailsSchema();

    const { registerField, formProps } = useMelioForm<CollectBusinessDetailsFormModel>({
      onSubmit,
      isSaving,
      schema,
      onSubmissionStateChange,
      defaultValues,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <Form {...formProps} ref={ref} data-component="CollectACH">
        <Form.TextField
          {...registerField('businessName')}
          labelProps={{
            label: formatMessage('vex.activities.collectBusinessDetails.fields.businessName.label'),
          }}
          placeholder={formatMessage('vex.activities.collectBusinessDetails.fields.businessName.placeholder')}
        />
        <Form.TextField
          {...registerField('email')}
          labelProps={{
            label: formatMessage('vex.activities.collectBusinessDetails.fields.email.label'),
          }}
          placeholder={formatMessage('vex.activities.collectBusinessDetails.fields.email.placeholder')}
        />
        <Form.TextField
          {...registerField('routingNumber')}
          labelProps={{
            label: formatMessage('vex.activities.collectBusinessDetails.fields.routingNumber.label'),
          }}
          placeholder={formatMessage('vex.activities.collectBusinessDetails.fields.routingNumber.placeholder')}
        />
        <Form.TextField
          {...registerField('accountNumber')}
          labelProps={{
            label: formatMessage('vex.activities.collectBusinessDetails.fields.accountNumber.label'),
          }}
          placeholder={formatMessage('vex.activities.collectBusinessDetails.fields.accountNumber.placeholder')}
        />
      </Form>
    );
  }
);

CollectBusienssDetailsForm.displayName = 'CollectBusienssDetailsForm';

import { ApiRequest } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import {
  ApiError,
  ApiQueryKeyV2,
  MutationCallbackOptions,
  MutationContext,
  OnMutationSuccess,
  PromiseFunctionReturnData,
  PromiseFunctionReturnType,
} from './types';
import { useOnUpdateErrorV2, useOnUpdateMutateV2 } from './utilV2';

type UseNonEntityMutationOptions<TData, TVariables, TError> = MutationCallbackOptions<
  TData,
  TVariables,
  TVariables,
  TError
> &
  (
    | { updateQuery: true | 'optimistic'; invalidateQuery?: never }
    | { updateQuery?: never; invalidateQuery: true }
    | { updateQuery?: never; invalidateQuery?: never }
  );

export const useNonEntityMutationV2 = <
  TQueryFn extends ApiRequest,
  TVariables = Parameters<TQueryFn>[0],
  TData = PromiseFunctionReturnData<TQueryFn> | PromiseFunctionReturnType<TQueryFn>,
  TError = ApiError
>(
  mutationFn: TQueryFn,
  queryKey: ApiQueryKeyV2,
  props: UseNonEntityMutationOptions<TData, TVariables, TError> = {}
) => {
  const queryClient = useQueryClient();

  const onMutate = useOnUpdateMutateV2<TData, TVariables, TError>(queryKey);
  const onError = useOnUpdateErrorV2<TData, TVariables, TError>(queryKey);

  const onSuccess: OnMutationSuccess<TData, TVariables> = async (data: TData) => {
    if (props.invalidateQuery) {
      await queryClient.invalidateQueries(queryKey);
    }
    if (props.updateQuery === true) {
      queryClient.setQueriesData(queryKey, data);
    }
    return Promise.resolve(data);
  };

  const fn = (args: TVariables) => mutationFn(args).then((res) => res.data as TData);

  const options: UseMutationOptions<TData, TError, TVariables, MutationContext<TData>> = {
    onSuccess: (...args) => {
      void onSuccess(...args);
      void props.onSuccess?.(...args);
    },
    onError: (...args) => {
      if (props.updateQuery === 'optimistic') {
        void onError(...args);
      }
      void props.onError?.(...args);
    },
  };

  if (props.updateQuery === 'optimistic') {
    options.onMutate = onMutate;
  }

  return useMutation<TData, TError, TVariables, MutationContext<TData>>(queryKey, fn, options);
};

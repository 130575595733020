import React from 'react';
import { Group, Icon, IconProps, NavigationItem, Text, useBreakpoint } from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';

import { useScrollIntoView } from '@/hooks/useScrollIntoView.hooks';

export enum SettingsCardIdEnum {
  ProfileSettings = 'profile-settings',
  CompanySettings = 'company-settings',
  CollaboratorsSetting = 'collaborators-setting',
  WorkflowsSetting = 'workflows-setting',
  NotificationPreferences = 'notification-preferences',
  AccountingSoftwareSync = 'accounting-software-sync',
  BillingStatements = 'billing-statements',
  PaymentMethodsSetting = 'payment-methods-setting',
  ReceivingMethodsSettings = 'receiving-methods-settings',
  BillingSetting = 'billing-setting',
  SupportSetting = 'support-setting',
  SubscriptionPlansSettings = 'subscription-plans-setting',
  InvoiceItems = 'invoice-items',
  InvoiceEmailNotifications = 'invoice-email-notifications',
  InvoiceSettings = 'invoice-settings',
  TaxAndReportsSettings = 'tax-and-reports',
}

type SettingsCardIdType = SettingsCardIdEnum;

export type SettingsCardProps = {
  id: SettingsCardIdType;
  iconType: IconProps['type'];
  content: string;
  onClick: VoidFunction;
  action?: React.ReactNode;
  isSelected: boolean;
  cardName: string;
  analyticsProps?: EventProperties;
};

export const SettingsCard = React.memo((props: SettingsCardProps) => {
  const { track } = useAnalytics();
  const {
    iconType,
    content,
    action = null,
    onClick,
    isSelected = false,
    cardName,
    id,
    analyticsProps,
    ...rest
  } = props;
  const cardRef = React.useRef<HTMLButtonElement>(null);
  const { isExtraSmallScreen } = useBreakpoint();
  useScrollIntoView({ ref: cardRef, isSelected });
  const analyticsFields = { PageName: id, Flow: 'settings', EntryPoint: 'settings-page', Cta: id, ...analyticsProps };

  const onClickCard = () => {
    track('Settings', 'Click', analyticsFields);
    onClick();
  };

  return (
    <NavigationItem
      {...rest}
      isFullWidth
      isSelected={isSelected}
      onClick={onClickCard}
      data-testid={`settings-card-${cardName}`}
      aria-current={isSelected ? 'page' : undefined}
      ref={cardRef}
      aria-label={content}
    >
      <Group alignItems="center">
        <Icon type={iconType} size="small" color="inherit" aria-hidden="true" />
        <Group as="section" variant="horizontal">
          <Text textStyle="body3" color="inherit">
            {content}
          </Text>
          {action}
        </Group>
        {isExtraSmallScreen && (
          <Group justifyContent="flex-end" width="full">
            <Icon type="chevron-right" size="small" aria-hidden="true" />
          </Group>
        )}
      </Group>
    </NavigationItem>
  );
});

SettingsCard.displayName = 'SettingsCard';

import { ExternalLayout } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useVexMyAccount, VexGuestPaymentResponse as PaymentData } from '@melio/platform-api';
import { DeliveryMethodSelectionByPayeeHeaderWidget } from '@melio/vex-widgets';
import { FC } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { WithActivityConfig } from '../../contexts/ActivityConfig';
import { PaymentCanceledScreen } from '../../shared/screens/payment-canceled';
import { ServerErrorScreen } from '../../shared/screens/server-error';
import { AcceptPaymentWithBankAccountDeliveryMethodParams } from '../../types';
import { AcceptPaymentWithBankAccountActivity } from '../accept-payment-with-bank-account';
import { useCreateDeliveryMethod } from '../add-bank-account/hooks/useCreateDeliveryMethod';
import { PaymentUpgradeActivity } from '../payment-upgrade';
import { usePaymentUpgrade } from '../payment-upgrade/usePaymentUpgrade';
import { UpgradePaymentFromCheckIndexScreen } from './screens/UpgradePaymentFromCheckIndex';
import { createActivityConfig, UpgradePaymentFromCheckConfigContext } from './UpgradePaymentFromCheck.config';

type PaymentDetailsHeaderProps = Pick<PaymentData, 'orgName' | 'note' | 'amount' | 'invoiceNumber'> & {
  showAvatar: boolean;
};

type UpgradePaymentFromCheckActivityProps = { paymentId: string };

export const PaymentDetailsHeader: FC<PaymentDetailsHeaderProps> = ({
  orgName,
  note,
  amount,
  invoiceNumber,
  showAvatar,
}) => (
  <DeliveryMethodSelectionByPayeeHeaderWidget
    showAvatar={showAvatar}
    accountName={orgName as unknown as string}
    paymentNote={note as unknown as string}
    paymentAmount={amount}
    paymentInvoiceNumber={invoiceNumber}
  />
);

const Activity: React.VFC<UpgradePaymentFromCheckActivityProps> = ({ paymentId }) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { isFetched: isAccountDataFetched, error: accountError, isGuest } = useVexMyAccount();

  const { error, isPaymentFetching, isPaymentUpgradeLoading, payment, paymentUpgradeOffers } = usePaymentUpgrade({
    paymentId,
  });

  const isFetchError = !!error || (!!accountError && !isGuest);

  const { createBankAccountDeliveryMethod, copyDeliveryMethodOwnedToLocalVendor } = useCreateDeliveryMethod({
    isGuest,
  });
  const acceptPaymentWithBankAccountDeliveryMethod = async ({
    bankAccountData,
    existingDeliveryMethodId,
  }: AcceptPaymentWithBankAccountDeliveryMethodParams) => {
    let deliveryMethodId: string | undefined;
    const analyticsParams = {
      Cta: 'verify-and-continue',
      Intent: 'bank-account-added',
      DeliveryMethodId: deliveryMethodId,
    };

    try {
      if (existingDeliveryMethodId !== undefined) {
        ({ deliveryMethodId } = await copyDeliveryMethodOwnedToLocalVendor(existingDeliveryMethodId));
      } else {
        ({ deliveryMethodId } = await createBankAccountDeliveryMethod(bankAccountData));
      }
      track('Vendor', 'Status', { ...analyticsParams, DeliveryMethod: deliveryMethodId });
    } catch (error) {
      track('Vendor', 'Status', {
        ...analyticsParams,
        StatusType: 'Failure',
        DeliveryMethod: existingDeliveryMethodId,
      });
      throw error;
    }

    navigate(`upgrade-payment/${deliveryMethodId as string}/index`);
  };

  if (isPaymentFetching || !isAccountDataFetched || isPaymentUpgradeLoading) {
    return <ExternalLayout isLoading />;
  }

  if (isFetchError || !payment) {
    return <ServerErrorScreen />;
  }

  if (payment.isCanceled) {
    return <PaymentCanceledScreen accountName={payment.orgName} accountEmail={payment.creatorAccountEmail} />;
  }

  return (
    <Routes>
      <Route
        index
        element={<UpgradePaymentFromCheckIndexScreen payment={payment} paymentUpgradeOffers={paymentUpgradeOffers} />}
      />
      <Route
        path="bank-account/*"
        element={
          <AcceptPaymentWithBankAccountActivity
            onAcceptPayment={acceptPaymentWithBankAccountDeliveryMethod}
            contentHeaderTitle={<PaymentDetailsHeader {...payment} showAvatar={false} />}
            payorOrganizationName={payment.orgName}
            paymentId={paymentId}
            cancelUrlFallback=""
            header={undefined}
          />
        }
      />
      <Route
        path="upgrade-payment/:newDeliveryMethodId/*"
        element={<PaymentUpgradeActivityWithDMParam paymentId={paymentId} />}
      />
    </Routes>
  );
};

const PaymentUpgradeActivityWithDMParam = ({ paymentId }: { paymentId: string }) => {
  const params = useParams<{ newDeliveryMethodId: string }>();
  return <PaymentUpgradeActivity paymentId={paymentId} deliveryMethodId={params.newDeliveryMethodId} />;
};
export const UpgradePaymentFromCheckActivity: React.VFC<UpgradePaymentFromCheckActivityProps> = (props) => (
  <WithActivityConfig Context={UpgradePaymentFromCheckConfigContext} createConfig={createActivityConfig}>
    <Activity {...props} />
  </WithActivityConfig>
);

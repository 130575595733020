import { matchRoutes } from 'react-router-dom';
import { decodeRedirectToken } from '@melio/partner-navigation-manager';

export const TARGET_ACTION_MAPPINGS = [
  {
    path: '/schedule-payment/:id',
    target: '/schedule-payment/:id',
    targetAction: 'schedulePayment',
    entityType: 'bill',
    constructTarget: ({ id }) => `/schedule-payment/${id}`,
  },
  {
    path: '/payment/new',
    idInQueryParam: true,
    shouldIncludeQueryParam: true,
    pathQueryParamKey: 'billId',
    target: '/schedule-payment/:id',
    targetAction: 'schedulePayment',
    entityType: 'bill',
    constructTarget: ({ id }) => `/schedule-payment/${id}`,
  },
  {
    path: '/batch-payments/:ids',
    target: '/schedule-batch-payments',
    isTargetContainQueryParam: true,
    targetQueryParamKey: 'bill_ids',
    targetAction: 'scheduleBatchPayments',
    entityType: 'bill',
    constructTarget: ({ ids }) => `/schedule-batch-payments?bill_ids=${ids}`,
  },
  {
    path: '/pay-dashboard/payments',
    target: '/view-payments',
    targetAction: 'viewPayment',
    constructTarget: () => '/view-payments',
  },
  {
    path: '/pay-dashboard/payments/:id',
    target: '/view-payment/:id',
    targetAction: 'viewPaidPayment',
    entityType: 'pymnt',
    constructTarget: ({ id }) => `/view-payment/${id}`,
  },
  {
    path: '/pay-dashboard/bills',
    target: '/view-bills',
    targetAction: 'viewBill',
    constructTarget: () => '/view-bills',
  },
  {
    path: '/pay-dashboard/bills/:id',
    target: '/view-bill/:id',
    targetAction: 'viewBill',
    entityType: 'bill',
    shouldIncludeQueryParam: false,
    constructTarget: ({ id }) => `/view-bill/${id}`,
  },
  {
    path: '/pay-dashboard/vendors',
    target: '/view-vendors',
    targetAction: 'viewVendors',
    constructTarget: () => '/view-vendors',
  },
  {
    path: '/pay-dashboard/vendors/vendor/:id',
    target: '/view-vendor/:id',
    targetAction: 'viewVendors',
    entityType: 'vendor',
    shouldIncludeQueryParam: false,
    constructTarget: ({ id }) => `/view-vendor/${id}`,
  },
  {
    path: '/settings',
    target: '/view-settings',
    targetAction: 'viewSettings',
    constructTarget: () => '/view-settings',
  },
  {
    path: '/ar/dashboard/invoices',
    target: '/ar/view-invoices',
    targetAction: 'viewArInvoices',
    constructTarget: () => '/ar/view-invoices',
  },
  {
    path: '/callback/:token',
    target: '/callback/:token',
    targetAction: 'redirect',
    constructTarget: ({ token }) => `/callback/${token}`,
  },
];

export function mapTargetToTargetAction(target: string) {
  const matches = matchRoutes([...TARGET_ACTION_MAPPINGS.map(({ target }) => ({ path: target }))], target);
  if (!matches || matches.length <= 0 || !matches[0].pathname) {
    return {};
  }
  const match = matches[0];
  const searchString = target.split('?')[1] || null;
  const searchParams = searchString ? new URLSearchParams(searchString) : null;

  const mapping = TARGET_ACTION_MAPPINGS.find(({ target }) => target === match.route.path);

  if (!mapping) {
    return {};
  }

  const extractedId =
    mapping.isTargetContainQueryParam && mapping.targetQueryParamKey
      ? searchParams?.get(mapping.targetQueryParamKey)
      : match.params.id;

  return {
    targetAction: mapping.targetAction,
    targetId: extractedId,
    redirectUrl: mapping.targetAction === 'redirect' ? decodeRedirectToken(match.params.token!).redirectUrl : undefined,
    entityType: mapping.entityType,
  };
}

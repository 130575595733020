import { ExternalLayout, IFrame, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useCollectW9Status, useUploadW9File } from '@melio/platform-api/src/vex/collect-w9';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useBoolean } from '@melio/platform-utils';
import { VexMonitoredAction } from '@melio/vex-domain';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { W9FileFormModel } from '../../types';
import { CollectW9FileErrorsScreen, CollectW9FileScreen, CollectW9FileSuccessScreen } from './screens';
import { useZenworkW9Form } from './useZenworkW9Form';

type CollectW9ActivityProps = {
  vendorId: string;
};

export const CollectW9Activity = withAnalyticsContext<CollectW9ActivityProps>(
  ({ vendorId, setAnalyticsProperties }) => {
    const { track } = useAnalytics();
    const { isLoading, data, error } = useCollectW9Status(vendorId);

    const { formatMessage, formatDate } = useMelioIntl();
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    const {
      isZenworkFormCompleted,
      zenworkLink,
      handleRequestZenworkUrl,
      isLoadingZenworkUrl,
      showIFrame,
      showZenworkW9Form,
    } = useZenworkW9Form(vendorId, data?.canFillFormManually);

    const { startAction, endAction } = useMonitoring<VexMonitoredAction>();

    const endW9FillManuallyAction = useCallback(() => {
      endAction(VexMonitoredAction.W9FillManually);
    }, [endAction]);

    const endaW9FileUploadAction = useCallback(() => {
      endAction(VexMonitoredAction.W9FileUpload);
    }, [endAction]);

    const { mutateAsync: upload, isLoading: isUploading } = useUploadW9File(vendorId);
    const [isLoadingIframe, loadingIframe] = useBoolean(false);
    const { toast } = useToast();
    const handleTaxPayerButtonClicked = () => {
      track('Vendor', 'Click', {
        PageName: 'add-your-tax-info',
        Intent: 'add-tax-info',
        TaxIdType: 'add-details',
        Cta: 'enter-taxpayer-info',
      });

      startAction(VexMonitoredAction.W9FillManually);

      void handleRequestZenworkUrl().catch(() => {
        track('Vendor', 'Status', {
          PageName: 'add-your-tax-info',
          Intent: 'add-tax-info',
          TaxIdType: 'add-details',
          ErrorType: 'something-went-wrong',
          Status: 'failure',
        });
      });
    };

    const handleUploadW9File = (formData: W9FileFormModel) => {
      track('Vendor', 'Click', {
        PageName: 'add-your-tax-info',
        Intent: 'upload-w9',
        TaxIdType: 'upload-pdf',
        Cta: 'upload-pdf',
      });
      startAction(VexMonitoredAction.W9FileUpload);

      const file = formData.w9file as File;
      void upload({ id: vendorId, file })
        .then(() => {
          setIsFileUploaded(true);
        })
        .catch(() => {
          toast({ type: 'error', title: formatMessage('vex.screen.collectW9File.error.general.title') });
          track('Vendor', 'Status', {
            PageName: 'add-your-tax-info',
            Intent: 'upload-w9',
            TaxIdType: 'upload-pdf',
            ErrorType: 'something-went-wrong',
            Status: 'failure',
          });
        });
    };

    const handleValidationError = (isRequiredError: boolean) => {
      track('Vendor', 'Status', {
        PageName: 'add-your-tax-info',
        Intent: 'upload-w9',
        TaxIdType: 'upload-pdf',
        ErrorType: isRequiredError ? 'file-is-empty' : 'file-is-too-long',
        Status: 'failure',
      });
    };

    const handleReplaceFile = () => {
      track('Vendor', 'Click', {
        PageName: 'add-your-tax-info',
        Intent: 'upload-w9',
        TaxIdType: 'upload-pdf',
        Cta: 'replace-file',
      });
    };

    useEffect(() => {
      if (showIFrame) {
        loadingIframe.on();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showIFrame]);

    if (isLoading) {
      return <ExternalLayout isLoading />;
    }

    if (!data || error) {
      return (
        <CollectW9FileErrorsScreen
          data-testid="collect-w9-general-error-screen"
          pageName="something-went-wrong"
          title={formatMessage('vex.screen.collectW9File.error.general.title')}
          description={formatMessage('vex.screen.collectW9File.error.general.description')}
          illustrationProps={{
            type: 'blocked',
            size: 'small',
          }}
        />
      );
    }
    setAnalyticsProperties({
      VendorId: vendorId,
      Flow: 'vendor-provide-tax-info',
      PayorId: data.userId,
      VendorType: data.isManaged ? 'directory' : 'local',
      OrganizationId: data.organizationId,
    });
    const AlreadySendScreen = (
      <CollectW9FileErrorsScreen
        data-testid="collect-w9-already-sent-screen"
        pageName="info-already-sent"
        title={formatMessage('vex.screen.collectW9File.error.sent.title')}
        description={formatMessage('vex.screen.collectW9File.error.sent.description', {
          date: formatDate(data.statusDate),
        })}
        illustrationProps={{
          type: 'sent',
          size: 'small',
        }}
      />
    );

    const RequestCanceledScreen = (
      <CollectW9FileErrorsScreen
        pageName="request-canceled"
        data-testid="collect-w9-request-canceled-screen"
        title={formatMessage('vex.screen.collectW9File.error.canceled.title')}
        description={formatMessage('vex.screen.collectW9File.error.canceled.description', {
          accountName: data.companyName,
          accountEmail: data.payorEmail,
        })}
        illustrationProps={{
          type: 'no-items',
          size: 'small',
        }}
      />
    );

    const PageNotFoundScreen = (
      <CollectW9FileErrorsScreen
        pageName="something-went-wrong"
        title={formatMessage('vex.screen.collectW9File.error.notFound.title')}
        description={formatMessage('vex.screen.collectW9File.error.notFound.description')}
        illustrationProps={{
          type: 'page-not-found',
          size: 'small',
        }}
      />
    );

    const GeneralErrorScreen = (
      <CollectW9FileErrorsScreen
        pageName="something-went-wrong"
        data-testid="collect-w9-general-error-screen"
        title={formatMessage('vex.screen.collectW9File.error.general.title')}
        description={formatMessage('vex.screen.collectW9File.error.general.description')}
        illustrationProps={{
          type: 'blocked',
          size: 'small',
        }}
      />
    );

    if (isFileUploaded || isZenworkFormCompleted) {
      track('Vendor', 'View', {
        PageName: 'taxpayer-info-submitted',
        Intent: 'add-tax-info',
        TaxIdType: isFileUploaded ? 'upload-pdf' : 'add-details',
      });

      const ref = isFileUploaded ? endaW9FileUploadAction : endW9FillManuallyAction;
      return <CollectW9FileSuccessScreen ref={ref} accountName={data.companyName} />;
    }

    return (
      <>
        <Routes>
          <Route index element={<CollectW9Navigator vendorId={vendorId} />} />
          <Route
            path="upload"
            element={
              <CollectW9FileScreen
                accountName={data.companyName}
                onUploadW9File={handleUploadW9File}
                onTaxPayerButtonClicked={handleTaxPayerButtonClicked}
                showZenworkW9Form={showZenworkW9Form}
                isLoadingZenworkUrl={isLoadingZenworkUrl || isLoadingIframe}
                isSaving={isUploading}
                onValidationError={handleValidationError}
                onReplaceFile={handleReplaceFile}
                showSuccessScreen={data.status === 'completed'}
              />
            }
          />
          <Route path="already-sent" element={AlreadySendScreen} />
          <Route path="request-canceled" element={RequestCanceledScreen} />
          <Route path="page-not-found" element={PageNotFoundScreen} />
          <Route path="success" element={<CollectW9FileSuccessScreen accountName={data.companyName} />} />
          <Route path="error" element={GeneralErrorScreen} />
        </Routes>
        {showIFrame && zenworkLink && (
          <IFrame
            title="zenwork-w9-form"
            src={zenworkLink}
            width="100%"
            height="100%"
            border="none"
            zIndex={1000}
            style={{
              position: 'absolute',
              top: window.innerWidth <= 600 ? '56px' : '72px',
              left: 0,
            }}
            onLoad={loadingIframe.off}
          />
        )}
      </>
    );
  }
);

const CollectW9Navigator: React.VFC<{ vendorId: string }> = ({ vendorId }) => {
  const { data, error } = useCollectW9Status(vendorId);
  const { track } = useAnalytics();
  if (error) {
    return <Navigate to="./error" />;
  }

  if (!data?.status) {
    return <Navigate to="./page-not-found" />;
  }
  if (data.status === 'completed') {
    return <Navigate to="./already-sent" />;
  }
  if (data.status === 'canceled') {
    return <Navigate to="./request-canceled" />;
  }
  if (data.status === 'pending') {
    track('Vendor', 'View', {
      PageName: 'add-your-tax-info',
      Intent: 'add-tax-info',
      TaxIdType: null,
      CanFillFormManually: data.canFillFormManually,
    });

    return <Navigate to="./upload" />;
  }
  return <Navigate to="./error" />;
};

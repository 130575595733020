import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AssociatedType, FileInfo, FilesApiClient } from '@melio/platform-api-axios-client';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { createUploadRequest } from './utils';

type QueryFn = typeof FilesApiClient.getFiles;

export type UseFilesProps = UseCollectionApiProps<QueryFn> & { associatedType?: AssociatedType };

export const useFiles = (props?: UseFilesProps) => {
  const queryClient = useQueryClient();
  const [isNewFileUploadEnabled] = useDevFeature(FeatureFlags.NewFileUploadEnabled, false);
  const query = useCollectionApi<QueryFn>({
    ...props,
    queryKey: 'FilesApi',
    queryFn: () => FilesApiClient.getFiles(),
  });

  const create = useMutation<FileInfo, unknown, File, FileInfo>(
    [...query.queryKey, 'create'],
    async (file: File) => {
      if (isNewFileUploadEnabled) {
        return createUploadRequest(file, props?.associatedType);
      }
      const res = await FilesApiClient.postFiles(file);
      return res.data.data;
    },
    {
      onSuccess: async (data: FileInfo) => {
        await queryClient.invalidateQueries([query.queryKey, data.id]);
      },
    }
  );

  const isFetching = query.isFetching;
  const isMutating = useMemo(() => create.isLoading || isFetching, [create.isLoading, isFetching]);

  return {
    ...query,
    create: create.mutateAsync,
    isCreating: create.isLoading,
    isMutating,
  };
};

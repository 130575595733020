import { datadogRum, RumFetchResourceEventDomainContext, RumXhrResourceEventDomainContext } from '@datadog/browser-rum';
import { Logger } from '@melio/platform-logger';
import React, { createContext, useMemo, useRef } from 'react';

import { ErrorBoundary } from './ErrorBoundary';

const absPathBlock = [
  'analytics.tiktok.com',
  'h.clarity.ms',
  'p.clarity.ms',
  'js.intercomcdn.com',
  'maps.googleapis.com',
  'launchdarkly-js-sdk',
  'googletagmanager.com',
  'safari-extension',
  'safari-web-extension',
  'chrome-extension',
  'cdn.nmgassets.com',
] as const;
const shouldDiscard = (stackTrace: string | undefined) =>
  stackTrace ? absPathBlock.some((path) => stackTrace.includes(path)) : false;

const tryParseResponse = (response: unknown) => {
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return JSON.parse(`${response}`) as unknown;
  } catch (error) {
    return {
      error,
      response,
    };
  }
};
const safelyGetResponseHeader = (xhr: XMLHttpRequest, headerName: string) => {
  const responseHeaders = xhr.getAllResponseHeaders();
  return responseHeaders.indexOf(headerName) >= 0 || responseHeaders.indexOf(headerName.toLocaleLowerCase()) >= 0
    ? xhr.getResponseHeader(headerName)
    : undefined;
};

type Props = {
  children: React.ReactNode;
  config: {
    environment: 'development' | 'production' | string;
    version: string;
  };
};

export type MonitoringAPI<T extends string> = {
  startAction: (action: T) => void;
  endAction: (action: T) => void;
  routeReady: (ref: HTMLElement | null) => void;
};

export const MonitoringContext = createContext<MonitoringAPI<string> | undefined>(undefined);

function getActionDuration(action: string) {
  if (performance && typeof performance.measure === 'function') {
    const measure = performance.measure(action, `${action}_start`);
    return measure?.duration;
  } else {
    return undefined;
  }
}

export function MonitoringProvider({ config, children }: Props) {
  const ongoingActions = useRef(new Set<string>());

  const applicationId = '1cae8098-c814-4441-84f4-d83a35abb272';
  const clientToken = 'pub012d37ef8b7e4b5efb04e359f4abf56c';
  const site = 'datadoghq.com';
  const service = 'platform-app';

  if (!datadogRum.getInternalContext() && config.environment !== 'development') {
    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      trackUserInteractions: true,
      trackFrustrations: true,
      env: config.environment,
      defaultPrivacyLevel: 'mask-user-input',
      version: config.version,
      beforeSend: (event, context) => {
        if (event.type === 'error') {
          const st = event.error?.stack;
          if (shouldDiscard(st)) {
            return false;
          }
        } else if (event.type === 'resource') {
          if (event.resource.type === 'fetch') {
            const fetchContext = context as RumFetchResourceEventDomainContext;
            event.context = {
              ...event.context,
              responseHeaders: fetchContext.response?.headers,
              responseBody: fetchContext.response?.body,
            };
          } else if (event.resource.type === 'xhr') {
            const xhrContext = context as RumXhrResourceEventDomainContext;
            const responseHeaders = xhrContext.xhr.getAllResponseHeaders();
            const requestId = safelyGetResponseHeader(xhrContext.xhr, 'Request-Id');
            const responseBody = tryParseResponse(xhrContext.xhr.response);
            event.context = {
              ...event.context,
              responseHeaders,
              requestId,
              responseBody,
            };
          } else if (event.resource.type === 'js' && 'performanceEntry' in context) {
            event.context = {
              performance: context.performanceEntry,
            };
          }
        }

        return true;
      },
      allowedTracingUrls: [`https://${window.location.hostname}/v1/`],
    });

    datadogRum.startSessionReplayRecording();
  }

  const monitoringContext = useMemo<MonitoringAPI<string>>(
    () => ({
      startAction(action) {
        if (performance && typeof performance.measure === 'function') {
          performance.mark(`${action}_start`);
        }
        datadogRum.addAction(`${action}_start`);
        Logger.log(`[Monitoring] ${action}_start`);
        ongoingActions.current.add(action);
      },
      endAction(action) {
        if (ongoingActions.current.has(action)) {
          const duration = getActionDuration(action);
          datadogRum.addAction(`${action}_end`, {
            duration,
          });
          Logger.log(`[Monitoring] ${action}_end`);
          ongoingActions.current.delete(action);
        }
      },
      routeReady(ref: HTMLElement | null) {
        if (ref) {
          datadogRum.addTiming('route_ready');
        }
      },
    }),
    []
  );

  return (
    <MonitoringContext.Provider value={monitoringContext}>
      <ErrorBoundary FallbackComponent={() => null}>{children}</ErrorBoundary>
    </MonitoringContext.Provider>
  );
}

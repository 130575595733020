import type { DynamicSettingsProps } from '@melio/i18n-tools';
import { Provider as MessagingProvider } from '@melio/in-app-marketing';
import { ApiProvider, ApiProviderProps, PartnerName } from '@melio/platform-api';
import { PlatformDSProvider } from '@melio/platform-ds/src/PlatformDSProvider';
import { EngagementProvider } from '@melio/platform-engagement';
import { AsyncLocalizationProvider, LocalizationProviderProps } from '@melio/platform-i18n';
import { PermissionsProvider } from '@melio/platform-permissions';
import { SystemMessageProvider } from '@melio/platform-utils';
import { getDeviceDataHeader } from '@melio/risk-data-collection';
import { useCallback } from 'react';

import { ConfigProvider, ConfigProviderProps } from './ConfigProvider';
import { FeedbackProvider } from './FeedbackProvider';
import { TBTProvider } from './TBTProvider';
import { FeedbackProviderProps } from './types';

export type MelioProviderProps = LocalizationProviderProps &
  ApiProviderProps &
  DynamicSettingsProps &
  ConfigProviderProps &
  FeedbackProviderProps & { partnerName?: PartnerName; permissionsEnabled?: boolean };

const getPartnerSessionHeaders = () => {
  const sessionData = sessionStorage.getItem('sessionData');
  return { 'x-partner-session-data': sessionData || '' };
};

export const MelioProvider: React.FC<MelioProviderProps> = ({
  config,
  accessToken,
  onTokenExpired,
  feedbackWidgetProps,
  translationsSrc,
  dynamicSettings,
  locale,
  permissionsEnabled,
  children,
  partnerName,
  ...rest
}) => {
  const isDefaultTZ = config?.settings?.defaultTZ?.enabled ?? false;
  const getEnrichedRequestHeaders = useCallback(
    () =>
      Promise.resolve({
        'x-site-context': partnerName ?? '',
        ...getDeviceDataHeader(),
        ...getPartnerSessionHeaders(),
      }).catch(() => ({})),
    [partnerName]
  );

  const shouldShowContentKeys = dynamicSettings?.shouldShowContentKeys ?? false;
  const isFullHeightMode =
    config?.settings?.embeddedExperience?.enabled && config.settings.embeddedExperience.singleScrollOwnedByHost;
  return (
    <ConfigProvider config={config}>
      <PlatformDSProvider config={{ isFullHeightMode }}>
        <ApiProvider
          apiServer={config?.server?.apiServer}
          accessToken={accessToken}
          onTokenExpired={onTokenExpired}
          getRequestHeaders={getEnrichedRequestHeaders}
          queryClient={rest?.queryClient}
        >
          <AsyncLocalizationProvider
            translationsSrc={translationsSrc}
            locale={locale}
            shouldShowContentKeys={shouldShowContentKeys}
            isDefaultTZ={isDefaultTZ}
          >
            <PermissionsProvider enabled={permissionsEnabled} accessToken={accessToken}>
              <FeedbackProvider feedbackWidgetProps={feedbackWidgetProps}>
                <TBTProvider>
                  <EngagementProvider accessToken={accessToken} config={{ production: config?.production }}>
                    <MessagingProvider>
                      <SystemMessageProvider>{children}</SystemMessageProvider>
                    </MessagingProvider>
                  </EngagementProvider>
                </TBTProvider>
              </FeedbackProvider>
            </PermissionsProvider>
          </AsyncLocalizationProvider>
        </ApiProvider>
      </PlatformDSProvider>
    </ConfigProvider>
  );
};

// eslint-disable-next-line no-restricted-imports
import {
  Container,
  Divider,
  Group,
  Layout as PennyLayout,
  LayoutProps as PennyLayoutProps,
  useBreakpoint,
} from '@melio/penny';
import { forwardRef } from 'react';

import { usePlatformDSConfig } from './PlatformDSProvider';

export const Layout = forwardRef<HTMLDivElement, PennyLayoutProps>(({ footer, children, ...rest }, ref) => {
  const { isFullHeightMode } = usePlatformDSConfig();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const shouldRenderFullHeightMode = isFullHeightMode && !isMobile;

  return shouldRenderFullHeightMode ? (
    <PennyLayout ref={ref} {...rest}>
      {children}
      {footer ? (
        <Container paddingTop="l">
          <Group variant="vertical" spacing="l">
            <Divider />
            {footer.content}
          </Group>
        </Container>
      ) : null}
    </PennyLayout>
  ) : (
    <PennyLayout
      footer={
        footer
          ? {
              ...footer,
              content: (
                <>
                  <Divider />
                  <Container paddingX="s" paddingY="s">
                    {footer.content}
                  </Container>
                </>
              ),
            }
          : undefined
      }
      ref={ref}
      {...rest}
    >
      {children}
    </PennyLayout>
  );
});

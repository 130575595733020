import { ExternalLayout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useIsMobile } from '@melio/vex-widgets';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useSessionConfig } from '../../contexts';
import { useUtmParameters } from '../../hooks/useUtmParameters';
import { ExternalUrlQueryParamErrorValues, ExternalUrlQueryParamKeys } from './consts';
import { useVendorOnboardingData } from './hooks/useVendorOnboardingData';
import { useVendorOnboardingTabBranding } from './hooks/useVendorOnboardingTabBranding';
import { VendorRegistrationLayout } from './layouts/VendorRegistration/VendorRegistration.layout';
import { AlreadyRegisteredScreen, RegistrationSucceededScreen } from './screens';
import { SessionExpiredScreen } from './screens/SessionExpired/SessionExpired.screen';
import { VendorOnboardingFormActivity } from './VendorOnboardingForm.activity';

type VendorOnboardingActivityProps = { onboardingSessionUuid: string };

export const VendorOnboardingActivity = withAnalyticsContext<VendorOnboardingActivityProps>(
  ({ onboardingSessionUuid, setAnalyticsProperties }) => {
    const { logoUrl, integrationName, theme, isLoading, faviconUrl, returnUrl, feeData, prePopulatedData } =
      useVendorOnboardingData(onboardingSessionUuid);

    useVendorOnboardingTabBranding({ faviconUrl, isLoading });
    const isMobile = useIsMobile();
    const utmParams = useUtmParameters();
    setAnalyticsProperties({
      Flow: 'erp-vendor-onboarding',
      onboardingSessionUuid,
      DeviceType: isMobile ? 'Mobile' : 'Desktop',
      PartnerName: integrationName,
      IsPopulated: false,
      ...utmParams,
    });
    const { overrideSessionExpiredRoute } = useSessionConfig();

    const SESSION_EXPIRED_ROUTE_PATH = 'expired-session';

    const { pathname } = useLocation();

    const getOnSessionExpired = () => {
      if (!returnUrl) {
        return undefined;
      }

      const onSessionExpired = () => {
        const errorUrl = new URL(returnUrl);
        errorUrl.searchParams.set(ExternalUrlQueryParamKeys.ERROR, ExternalUrlQueryParamErrorValues.SESSION_EXPIRED);

        window.location.href = errorUrl.toString();
      };

      return onSessionExpired;
    };

    useEffect(() => {
      const sessionExpiredRoute = `${pathname}/${SESSION_EXPIRED_ROUTE_PATH}`;
      overrideSessionExpiredRoute(sessionExpiredRoute);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return <ExternalLayout isLoading />;
    }

    const goBackToPartnerDashboard = returnUrl
      ? () => {
          window.location.href = returnUrl;
        }
      : undefined;

    return (
      <Routes>
        <Route element={<VendorRegistrationLayout integrationName={integrationName} logoUrl={logoUrl} theme={theme} />}>
          <Route
            index
            element={
              <VendorOnboardingFormActivity
                onboardingSessionUuid={onboardingSessionUuid}
                feeData={feeData}
                prePopulatedData={prePopulatedData}
              />
            }
          />
          <Route
            path="success"
            element={<RegistrationSucceededScreen onGoBackToPartnerDashboardClick={goBackToPartnerDashboard} />}
          />
          <Route
            path="already-registered"
            element={
              <AlreadyRegisteredScreen
                onGoBackToPartnerDashboardClick={goBackToPartnerDashboard}
                partnerName={integrationName}
              />
            }
          />
          <Route
            path={SESSION_EXPIRED_ROUTE_PATH}
            element={<SessionExpiredScreen onContinue={getOnSessionExpired()} />}
          />
        </Route>
      </Routes>
    );
  }
);

VendorOnboardingActivity.displayName = 'VendorOnboardingActivity';

import { Route, Routes, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CollectBusinessDetailsActivity, CollectBusinessDetailsSuccess } from '@melio/vex-activities';

export const CollectBusinessDetailsRoute = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { businessId } = useParams();
  const businessLegalName = params.get('business_legal_name');
  const businessEmail = params.get('business_email');

  const goToSuccess = () => {
    navigate('success');
  };

  return (
    <Routes>
      <Route
        index
        element={
          <CollectBusinessDetailsActivity
            businessId={businessId || ''}
            businessLegalName={businessLegalName || ''}
            businessEmail={businessEmail || ''}
            onSuccess={goToSuccess}
          />
        }
      />
      <Route path="success" element={<CollectBusinessDetailsSuccess />} />
    </Routes>
  );
};

CollectBusinessDetailsRoute.displayName = 'CollectBusinessDetailsRoute';

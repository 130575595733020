import { FC } from 'react';

import { useDispatchState } from '../DispatchEventsWrapper';

type BlanketProps = {
  isOpen?: boolean;
  isFullScreen?: boolean;
};

export const Blanket: FC<BlanketProps> = ({ isOpen, isFullScreen }) => {
  useDispatchState(!!isOpen, !!isFullScreen);
  return null;
};

import { useParams } from 'react-router-dom';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { UpgradePaymentFromCheckActivity } from '@melio/vex-activities';

export const UpgradePaymentFromCheckRoute = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { paymentId } = useParams<{ paymentId: string }>();
  setAnalyticsProperties({ paymentId, EntryPoint: 'upgrade-payment-from-check', Flow: 'upgrade-payment-from-check' });

  if (!paymentId) {
    return null;
  }

  return <UpgradePaymentFromCheckActivity paymentId={paymentId} />;
});

UpgradePaymentFromCheckRoute.displayName = 'UpgradePaymentFromCheckRoute';
